import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import ModalContentContainer from './ModalContentContainer';

export interface RecoveryCodeContainerProps {
  recoveryCode: string;
  logo?: string;
  error: string;
  onClickRecoveryCode: () => void;
  onChangeRecoveryCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const RecoveryCodeContainer = ({
  recoveryCode,
  logo,
  error,
  onClickRecoveryCode,
  onChangeRecoveryCode,
}: RecoveryCodeContainerProps) => {
  const theme = useTheme();

  return (
    <Box>
      <ModalContentContainer
        image={ logo }
        title="New Device Detected"
        description="Please enter the recovery code emailed to you when you first signed up" />
      <Typography sx={{ marginBottom: '15px', textAlign: 'center' }}>The email title is Your recovery code to Pace Verso</Typography>
      <TextField
        sx={{ width: '100%', marginBottom: '20px' }}
        InputProps={{
          sx: {
            height: '40px',
            color: theme?.palette?.common?.black,
          },
        }}
        value={ recoveryCode }
        placeholder="Recovery code"
        error={ !!error }
        onChange={ onChangeRecoveryCode }
        helperText={ error } />
      <Button data-testid="onClick_recovery_code" sx={{ width: '100%', marginBottom: '15px' }} onClick={ onClickRecoveryCode }>
        Continue
      </Button>
    </Box>
  );
};
export default RecoveryCodeContainer;
