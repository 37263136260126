import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle(
  ({ theme }) => `
 


  html,
  body {
    color: ${theme.colors.text};
    background-color: ${theme.colors.background};
    padding: 0;
    margin: 0;
    font-family: 'untitled_sansregular_regular';
    font-weight: 400;
  }

  .MuiPaper-root{
  }
  a {
    color: #000000;
    cursor: pointer;
    
  }
  .alex {
    color: #000;
    background-color: red;
    padding: 20px;
    border: 1px solid green;
  }
  button {
    align-items: center;
    background-color: ${theme.colors.primary};
    border: none;
    color: ${theme.colors.background};
    cursor: pointer;
    display: flex;
    font-family: "${theme.fonts.primary}";
    font-size: 26px;
    font-weight: bold;
    justify-content: center;
  }

  button:disabled,
  button[disabled] {
    background-color: ${theme.colors.disabled};
    color: ${theme.colors.text};
    cursor: initial;
  }

  h1 {
    font: ${theme.fonts.h1};
  }

  h2 {
    font: ${theme.fonts.h2};
  }

  h3 {
    font: ${theme.fonts.h3};
  }

  .reactSelect__control {
    background: none;
    border: none;
    width: max-content;
  }
  
  * {
    box-sizing: border-box;
  }

  // selvakumar style
  *{
    padding:0;
    margin:0;
  }
  .container{
   // padding-left: 39px;
    // padding-right: 50px;
    padding-left:55px;
    padding-right:55px;


  }
  .sub-heading{
    // font-family: 'Untitled Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    letter-spacing: -0.02em;
    color: #000000;
    padding: 70px 0 40px
  }

  .padding-section{
    padding-top:80px;
    padding-bottom:80px;
    position: relative;
    border-bottom: 1px solid #DCDCD4;
  }

  .MuiAccordionDetails-root {
    padding: 0px;
  }

  .MuiAccordionSummary-root {
    padding: 0px;
  }

  @media only screen and (max-width: 767px) {
    .padding-section{
      padding-top:40px;
      padding-bottom:40px;
    }
    .container{
      padding-left: 14px;
      padding-right: 14px;
    }
    
  }
 .img-responsive{
    max-width: 100%;
    height: auto;
 }

 .custominput .Mui-focused {
  color: black !important;
}
.custominput .MuiInput-root::after{
  border-bottom: 2px solid black;
}
.custominput .Mui-focused .MuiSelect-select{
  background-color:transparent;
}
// .MuiModal-root .MuiBackdrop-root{
//   background: #ffffffd4;
// }

  .brdrText span {
    display: inline-flex;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 23px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
  }
  
.tab_main .Mui-selected {
  color: #000000;
}
.tab_main .MuiTabs-indicator{
  background-color:#000000;
}
.tab_main .inner_section_box{
  padding:24px 0px;
}
.modal_main.MuiBox-root:focus-visible {
  outline: none!important;
}
.bglightgray {
  background-color: #F9F9F9;
  padding: 10px;
}
.mrgTop20 {
  margin-top: 20px;
}
.inputField100 {
  position:relative;
  .custominput {
      .MuiFormControl-root {
          width: 100% !important;
      }
  }
}
.maincontWrpper{
  min-height:calc(100vh - 125px)
}
.success {
  .MuiPaper-root {
      background: #CFFAD5;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
      .MuiAlert-icon,.MuiAlert-message,.MuiAlert-action{
        padding:0px;
      }
      .MuiAlert-message{
        margin-right:8px;
      }
  }
}
.error {
  .MuiPaper-root {
      background:#f38080;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #CE202A;
      svg{
        path{
          fill:#CE202A;
        }
      }
      .MuiAlert-icon,.MuiAlert-message,.MuiAlert-action{
        padding:0px;
      }
      .MuiAlert-message{
        margin-right:8px;
      }
  }
}
.box-shadow .MuiPaper-root{
  box-shadow: 0px 1px 4px rgb(0 0 0 / 20%)!important;
}
.box-shadow .MuiPaper-root .MuiList-root{
  padding-top:0px;
  padding-bottom:0px;
}
.box-shadow .MuiPaper-root .MuiList-root .MuiMenuItem-root{
  min-height:40px;
  
}
.salestart{
  color:#5F85BD!important;
}
.saleend{
  color: #737373!important;
}
.salesStart_statusBlock__kueFA .NFTItem_salesStart__GBonu.salestart{display:flex;}
.salesStart_statusBlock__kueFA .NFTItem_salesStart__GBonu.salestart span{
  color:#5F85BD!important;
  display:flex;
  margin-right:5px;
}
.salesStart_statusBlock__kueFA .NFTItem_salesStart__GBonu.salestart span.time{
  margin-left:5px;
}
@media only screen and (max-width: 600px) {
  .hide_mobile {
    display: none;
  }
  .salesStart_statusBlock__kueFA .NFTItem_salesStart__GBonu.salestart span{
    display:flex;
    flex-direction: column;
    ustify-content: flex-start;
    text-align: left;
    align-items: flex-start;
  }
  .salesStart_statusBlock__kueFA .NFTItem_salesStart__GBonu.salestart span.time{
    flex-direction: column;
    color:#000 !important;
    font-size:13px !important;
    margin-left:0px;

  }
  .salesStart_statusBlock__kueFA .NFTItem_salesStart__GBonu.salestart span{
    color:#000 !important;
  }
  .NFTItem_salesStart__GBonu.salestart{
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;
  }
}
header .MuiToolbar-root .css-1r0b0y8-MuiTypography-root{
  // display:flex !important;
}
.cursor-p{
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}
.cursor-normal{
cursor:default;
}
.MuiButtonBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgb(0 0 0 / 4%) !important;
}

.MuiButton-outlinedPrimary {
  border: 1px solid #D7D8DB;
  color: #242629;
  background: #FFFFFF;
}

.textDecoder{
  text-decoration: none;
}
.figure{
  margin-bottom:10px;
}
.figure img{
  //background:#f1f1f1;
  margin: unset!important;
  width: auto!important;
  min-width: auto!important;
 
}
.card_main{
  transform: translateY(0px);
  transition: all 0.3s ease-in;
  margin-bottom:30px;
}
// .card_main:hover{
//   transform: translateY(-30px);
//   transition: all 0.4s ease-in;
// }

.card_main:hover{
  transform: translateY(-30px);
  transition: all 0.3s ease-in;
  filter: drop-shadow(0 4px 14px rgba(0,0,0,.11));
  
}
.login-btn button{
  padding: 6px 16px!important;
}

@media (max-width: 768px) {
  .login-btn button{
    font-size:14px!important;
  }
}

.search-box-wrap .MuiFormLabel-root {
  display: none;
}
.search-box-wrap .MuiInputBase-root {
  border-radius: 0px;
  height: 77px;
}
.search-box-wrap.white .MuiInputBase-root {
  color:white!important;
}
.search-box-wrap.white span svg path{
  fill:white!important;
}
.search-box-wrap .MuiInputBase-root .MuiInputBase-input {
  font-size: 64px;
  font-weight: 400;
  padding-right: 80px;
}
.search-box-wrap .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.search-box-wrap.white .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}
@media (max-width: 768px) {
  .search-box-wrap .MuiInputBase-root {
    border-radius: 0px;
    height: 60px;
  }
  .search-box-wrap .MuiInputBase-root .MuiInputBase-input {
    font-size: 32px;
    font-weight: 400;
    padding-right: 55px;
  }
}
@media (min-width: 1440px) {
  .search-box-wrap .MuiInputBase-input {
    padding-right: 100px!important;
  }
}

::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #D7D8DB; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}

* {
  scrollbar-width: thin;
  scrollbar-color: #D7D8DB #f1f1f1;
}

.faqlist_wrap .MuiAccordion-root {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding: 24px 30px;
}
.faqlist_wrap .MuiAccordion-root.Mui-expanded{
  border: 1px solid #000000!important;
}

@media (max-width: 768px) {
  .faqlist_wrap .MuiAccordion-root {
    padding: 12px 8px;
  }
}
.faqlist_wrap .MuiAccordion-root .MuiTypography-root {
  font-size: 16px;
  color: #000000;
}
.faqlist_wrap .MuiAccordion-root .drop-icn {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.faqlist_wrap .MuiAccordion-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}
.faqlist_wrap .MuiAccordion-root::before {
  display: none;
}
.faqlist_wrap .MuiAccordion-root:last-child, .faqlist_wrap .MuiAccordion-root:first-of-type {
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card_title_mob{
  display:none;
}
@media (max-width: 550px) {
  .hide_mobile {
    display: none;
  }
  .card_title_mob{
    display:flex;
  }
  .card_title_desk{
    display:none;
  }
}
.transparency2 ul{
  background-color: #e6e6e612;
}
.transparency3{
  border: 1px solid #dcdcdb21;
}

@media (max-width: 991px) {
    .tranperancy::before {
        background: #f9f9f900 !important;
    }
}
@media (max-width: 479px){
  .hide_mobile {
    display: none;
  }
}
html {
  scroll-behavior: smooth;
}
.drawer-bg .MuiDrawer-paper{
  background: #101010!important;
}
`
);
