import { Auth0Provider } from "@auth0/auth0-react";
import { Configuration } from "@constants";
import { useRouter } from "next/router";
import { ReactNode } from "react";

export interface IAuth0State {
  returnTo?: string;
  origin?: string;
  query?: { [key: string]: string };
}

export const AuthProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const router = useRouter();
  // const history = useHistory();

  const onRedirectCallback = (appState?: IAuth0State) => {
    router.push({
      pathname: appState?.returnTo || window.location.pathname,
      query: { redirect_url: appState?.origin, ...appState?.query },
    });
  };

  return (
    <Auth0Provider
      domain={
        process.env.NEXT_PUBLIC_AUTH0_DOMAIN ??
        "https://mojito-demo-site.us.auth0.com"
      }
      clientId={
        process.env.NEXT_PUBLIC_AUTH0_CLIENTID ??
        "oUgVZGrq4CJy8vBdOgUY1787pesrsTqD"
      }
      redirectUri={Configuration.AUTH0_REDIRECT_URI}
      // onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
