export const strings = {
  SITE_TITLE: "Cantina",

  COMMON: {
    LOGIN_BUTTON: "SIGN UP",
    TERMS_AND_CONDITIONS: "Terms and Conditions",
    CURRENT_BID: "Current bid: ",
    AUCTION_FINISHED: "Auction finished",
    BUYNOW_FINISHED: "Sale is complete ",
    BIDDING_STARTS: "Bidding starts on ",
    AUCTION_CLOSES: "Auction closes in ",
    BUYNOW_CLOSES: "Sale closes in ",
    BUYNOW_STARTS: "Sale starts in ",
    WINNER: "Winner ",
    CREATED_BY: "Created by ",
    SEE_MORE: "See more",
    SEE_LESS: "See less",
    YOU: "You",
    ERROR_GETTING_DATA: "error getting data",
    UNSUPPORTED_ITEM_TYPE: "unsupported item type",
    INVALID_TYPE: "invalid type",
  },
  GRID: {
    TITLE: "Cantina",
    SUBTITLE: "Example NFT Marketplace",
    DATE_AND_LOCATION: "Dec 7, 2021 Museo de Arte de Puerto Rico",
  },
  ITEM: {
    LOGIN_BUTTON: "SIGN IN",
    BID_AGAIN_BUTTON: "BID AGAIN!",
    BID_NOW_BUTTON: "BID NOW!",
    BUY_NOW: "BUY NOW",
    NOT_ALLOWED: "NOT ALLOWED",
    BID_SENT: "YOUR BID WAS SENT",
    SET_USERNAME: "SET USERNAME TO BID",
    AVAILABLE_SOON: "AVAILABLE SOON",
    HIGHEST_BID: "Your bid is the highest so far 🥇",
    OUTBID: "You have been outbid! ⚠️",
    WINNING_BID: "Winning Bid: ",
    BY: "By ",
    SOLD_OUT: "Sold out",
    BID_FEED: {
      TITLE: "Bid feed",
      WARNING_MESSAGE:
        "You don't hold the leading bid because you placed your responsive high bid after the leading user.",
      WARNING_ICON: "⚠️",
    },
    CONFIRM_MODAL: {
      TITLE: "Confirm your bid for ",
      BUTTON_TITLE: "CONFIRM BID",
      MAX_TOTAL: "max. Total",
      YOUR_MAX_BID: "Your max. bid",
      DISCLAIMER:
        "If you place your maximum limit, the system will automatically keep you at the top within the next increment until your max bid is met.",
      SUCCESS: "Your bid was submitted successfully!",
      CLOSE: "CLOSE",
    },
  },
  PROFILE: {
    LOG_OUT_BUTTON: "LOG OUT",
    CANCEL_BUTTON: "CANCEL",
    EDIT_BUTTON: "EDIT",
    SAVE_BUTTON: "SAVE",
    LOADING: "LOADING...",
    EDIT_SUCCESS: "Your username was changed successfully!",
    BIDDING_SCORE: "Bidding score:",
    ACTIVE_BIDS: "MY ACTIVE BIDS",
    HOME_BUTTON: "GET STARTED",
    PLACEHOLDER: "You don't have any bids going on now",
  },
  WALLET: {
    OWNER_SUCCESS_MESSAGE: "Your account has valid access!",
    OWNER_FAILURE_MESSAGE: "Your account does not have access",
  },
  REDEEM: {
    DESCRIPTION: {
      INFORMATION: "Information",
      REDEMPTION_CODE:
        "Please enter the redemption code you received in via email below:",
      SELECT_WALLET: "Select wallet address",
    },
    LABEL: {
      FULL_NAME: "Full Name",
      REDEMPTION_CODE: "Redemption Code",
    },
    ALERT: {
      FAIL: {
        TITLE: "Failed to redeem",
        MESSAGE:
          "Failed to redeem your item. Please try again with correct code.",
      },
      CODE_REQUIRED: {
        TITLE: "Code is required",
        MESSAGE:
          "Please enter the redemption code you received in via email below.",
      },
      WALLET_REQUIRED: {
        TITLE: "Wallet address is required",
        MESSAGE: "Please select the wallet address.",
      },
      SUCCESS: {
        TITLE: "Successfully redeemed",
        MESSAGE: "Your item has been redeemed successfully! ",
        WALLET_VISIT: "Check my wallet",
      },
      CLOSE: "Close",
    },
    BUTTON: "Redeem",
  },
};
