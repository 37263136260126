export function getCurrencyAddress(chainId: number): string {
  if (chainId === 80001) {
    return '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889';
  } if (chainId === 1) {
    return '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
  } if (chainId === 5) {
    return '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6';
  } if (chainId === 137) {
    return '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270';
  } if (chainId === 11155111) {
    return '0xb0Ac866E8951bC07efBF738580F7f17900cebE15';
  }
  return '';
}
