import { useAuth0 } from "@auth0/auth0-react";
import { Configuration } from "@constants";
import { useIsomorphicLayoutEffect, usePrevious } from "ahooks";
import { GraphQLClient } from "graphql-request";
import { Variables } from "graphql-request";
import { useQuery } from "react-query";
import {
  UseQueryOptions,
} from "react-query/types/react/types";
import {
  EMojitoQueries,
  IUseQueryResult,
  mojitoQueries,
} from "../data/index";
import {
  IMojitoCollection,
} from "../interfaces/index";
import { mojitoNormalizer } from "../utils/gqlDataNormalizer.util";
import { gqlRequest, queryClient } from "../utils/index";
export const mojitoGqlClient = new GraphQLClient(
  Configuration.MOJITO_API_URL || ""
);

export interface IUseMojitoOptions<T = any, V = Variables> {
  query: EMojitoQueries;
  variables?: any;
  options?: UseQueryOptions<T>;
  force?: boolean;
  onlyAuthenticated?: boolean;
}

export function useMojito<T = any, V = Variables>({
  query,
  variables,
  options,
  force = false,
  onlyAuthenticated,
}: IUseMojitoOptions<T, V>): IUseQueryResult {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const notIsAuthenticated = usePrevious(isAuthenticated);
  const queryKey: any = [`Mojito ${EMojitoQueries[query]}`, variables];

  const result = useQuery<T>(
    queryKey,
    async () => {
      if (isAuthenticated) {
        const token = await getIdTokenClaims();
        if (typeof window !== "undefined") {
          window.localStorage.setItem('authToken', `Bearer ${token ? token.__raw : ''}`)
          window.sessionStorage.setItem('authToken', `Bearer ${token ? token.__raw : ''}`)
        }
        if (token) {
          mojitoGqlClient.setHeader("authorization", `Bearer ${token.__raw}`);
        }
      } else if (onlyAuthenticated) {
        return null;
      }

      if (Object.values(variables ?? {}).some((e) => !e)) {
        console.error("some of vars is undefined", variables);
        return null;
      }
      return await gqlRequest<T>({
        query: mojitoQueries[query],
        variables,
        normalizerFn: mojitoNormalizer,
        gqlClient: mojitoGqlClient,
      });
    },
    {
      ...options,
      meta: { authorization: isAuthenticated },
      enabled: !onlyAuthenticated,
    }
  );

  useIsomorphicLayoutEffect(() => {
    if (force) {
      queryClient.removeQueries(queryKey);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isAuthenticated && !notIsAuthenticated) {
      if (
        onlyAuthenticated &&
        queryClient.getQueryData(queryKey) == undefined
      ) {
        result.refetch();
      }
    }
  }, [isAuthenticated]);

  if (result.isError) {
    console.log(result.error);
  }

  return {
    loading: result.isLoading,
    ...result,
  };
}

export function useLazyMojito<T = any, V = Variables>({
  query,
  variables,
  options,
  force = false,
  onlyAuthenticated,
}: IUseMojitoOptions<T, V>): [
    (options?: UseQueryOptions<T>) => void,
    IUseQueryResult
  ] {
  options = {
    enabled: false,
    ...options,
  };
  const result = useMojito({
    query,
    variables,
    options,
    force,
    onlyAuthenticated,
  });

  return [
    result.refetch,
    {
      ...result,
    },
  ];
}

export function useMarketplaceCollectionsSlugWithItemsId(): {
  marketplaceCollectionsSlugWithItemsId: IMojitoCollection[];
  marketplaceCollectionsSlugWithItemsIdLoading: boolean;
  marketplaceCollectionsSlugWithItemsIdError: IUseQueryResult["error"];
} {
  const { data, error, loading } = useMojito({
    query: EMojitoQueries.marketplaceCollectionsInfoWithItemsIdAndSlug,
    variables: { id: Configuration.MARKETPLACE_ID },
  });

  if (error) console.error(error);

  return {
    marketplaceCollectionsSlugWithItemsId: data?.marketplace?.collections,
    marketplaceCollectionsSlugWithItemsIdLoading: loading,
    marketplaceCollectionsSlugWithItemsIdError: error,
  };
}
