"use client";

import React from 'react';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
import { Configuration } from '@constants';

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

const metadata = {
  name: 'Pace verso',
  description: 'Pace Verso is Pace Gallery’s hub for integrated Web3 artworks, launched in 2021 under the leadership of Pace CEO Marc Glimcher. Pace Verso works closely with artists within and beyond the gallery’s program to incubate, develop, and realize their Web3 projects.',
  url: 'https://verso.pacegallery.com/',
  icons: ['https://avatars.mywebsite.com/']
}

const projectId = Configuration.PROJECT_ID;

interface WalletConnectProviderProps {
    children: JSX.Element;
}

export const WalletConnectProvider = ({ children }: WalletConnectProviderProps) => {
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId
  });
  return children;
};
