import Button from "@mui/material/Button";
import buttonstyl from "./button.module.css";

interface ButtonProps {
  variant?: any;
  text?: String;
  cls?: any;
  handleClick?: any;
  disable?: any;
}

const ButtonComponent = ({
  variant,
  text,
  cls,
  handleClick,
  disable,
}: ButtonProps) => {
  return (
    <>
      <Button
        variant={variant}
        className={buttonstyl[cls]}
        onClick={handleClick}
        disabled={disable}
      >
        {text}
      </Button>
    </>
  );
};

export default ButtonComponent;
