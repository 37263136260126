import { Configuration } from "@constants";
import axios from "axios";
import { createClient } from "contentful";

export class ContentfulService {
  constructor() {}
  private client = createClient({
    accessToken: Configuration.CONTENTFUL_ACCESS_TOKEN,
    space: Configuration.CONTENTFUL_SPACE,
    environment: Configuration.CONTENTFUL_ENV,
  });

  public getLotItem(itemId: string) {
    return this.client.getEntries({
      content_type: "lot",
      "fields.mojitoItemId": itemId,
    });
  }

  public getCollectionItemById(itemId: string) {
    return this.client.getEntries({
      content_type: "projectList",
      "fields.mojitoCollectionId": itemId,
    });
  }

  public getLotItemWallet(contractAddress: string, tokenId: string) {
    return this.client.getEntries({
      content_type: "lot",
      "fields.contractAddress": contractAddress,
      "fields.tokenId": tokenId,
    });
  }

  public getHomePageItem() {
    return this.client.getEntries({
      content_type: Configuration.CONTENTFUL_LANDING_PAGE,
      // 'fields.mojitoItemId': itemId,
    });
  }
  public getCheckoutConfig() {
    return this.client.getEntries({
      content_type: "checkout",
      // 'fields.mojitoItemId': itemId,
    });
  }

  public getCaption() {
    return this.client.getEntries({
      content_type: "caption",
    });
  }

}
// accessToken: Configuration.CONTENTFUL_ACCESS_TOKEN,
//     space: Configuration.CONTENTFUL_SPACE,
//     environment: Configuration.CONTENTFUL_ENV,

export const FieldRecursion = async (id: string): Promise<false | any> => {
  if (id) {
    const promise: any = await axios.get(
      "https://cdn.contentful.com/spaces/" +
        Configuration.CONTENTFUL_SPACE +
        "/environments/" +
        Configuration.CONTENTFUL_ENV +
        "/entries/" +
        id +
        "?access_token=" +
        Configuration.CONTENTFUL_ACCESS_TOKEN +
        " "
    );
    return promise;
  }
  return false;
};

//get image or video entry contentful
export const assetChecking = async (
  id: string
): Promise<false | Promise<any>> => {
  if (id) {
    const asset: any = await axios.get(
      "https://cdn.contentful.com/spaces/" +
        Configuration.CONTENTFUL_SPACE +
        "/environments/" +
        Configuration.CONTENTFUL_ENV +
        "/assets/" +
        id +
        "?access_token=" +
        Configuration.CONTENTFUL_ACCESS_TOKEN +
        "  "
    );

    return asset;
  }
  return false;
};
