import React from "react";
import Head from "next/head";

interface HeaderProps {
  title?: string;
  description?: string;
}
const Header: React.FC<HeaderProps> = ({ title, description }: HeaderProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        rel="icon"
        href="https://www.pacegallery.com/static/images/favicon.dc63b0105878.ico"
      />
    </Head>
  );
};

export default Header;
