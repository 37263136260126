import * as React from "react";
import Image from "next/image";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LoaderIcon from "../../../../public/images/loading.gif";
import ErrorIcon from "../../../../public/images/error.svg";

const style = {
  position: "absolute" as "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  padding: "20px",
  minWidth: '500px',
  paddingBottom: '30px',
};

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  processing?: {
    message: string;
    status: boolean;
  };
  error?: {
    message: string;
    status: boolean;
  };
  success?: {
    message: string;
    status: boolean;
  };
  disConnect?: {
    message: string;
    status: boolean;
  };
}

export const ErrorSuccessModal = styled(
  ({ open, handleClose, processing, error, success, disConnect, ...props }: ModalProps) => {
    const getData = () => {
      if (processing?.status) {
        return {
          message: processing?.message,
          status: "processing",
        };
      } else if (error?.status) {
        return {
          message: error?.message,
          status: "error",
        };
      } else if (success?.status) {
        return {
          message: success?.message,
          status: "success",
        };
      } else if (disConnect?.status) {
        return {
          message: disConnect?.message,
          status: "disconnect",
        };
      } else {
        return {
          message: "",
          status: "",
        };
      }
    };
    const data = getData();
    return (
      <Modal
        {...props}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          {data?.status !== '' ? (
            <Box sx={style}>
              {(data?.status === "error" || data?.status === "success" || disConnect?.status) && (
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </Box>
              )}
              <Box className="processing-container">
                {data?.status === "processing" ? (
                  <Box sx={{ marginBottom: '30px' }}>
                    <Image
                      src={LoaderIcon}
                      alt={"loading"}
                      height={150}
                      width={150}
                      unoptimized
                    />
                  </Box>
                ) : data?.status === "error" ? (
                  <Box sx={{ marginBottom: '20px' }}>
                    <Image
                      src={ErrorIcon}
                      alt={"loading"}
                      height={100}
                      width={100}
                      unoptimized
                    />
                  </Box>
                ) : (
                  <CheckCircleOutlineIcon
                    sx={{ fontSize: 120, color: "#158C22", marginBottom: '20px' }}
                  />
                )}
                {data?.status === "processing" ? (
                  <Typography className="processing-title">
                    {data?.message === 'Processing...' ? data?.message : `Please sign a message using your wallet ${data?.message} to continue.`}
                  </Typography>
                ) : (
                  <Typography sx={{ width: '450px' }} className="processing-title">
                    {data?.message}
                  </Typography>
                )}
                {disConnect?.status && (
                  <Button sx={{ marginTop: '20px', width: '90%', height: '40px' }} onClick={handleClose} variant="outlined">Continue</Button>
                )}
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Fade>
      </Modal>
    );
  }
)`
  .processing-title {
    text-align: center;
  }
  .processing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
