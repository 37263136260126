import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { MuiOtpInput } from 'mui-one-time-password-input';
import ModalContentContainer from './ModalContentContainer';
import { ContentData } from '../interface';

export interface OTPContainerProps {
  email: string;
  otp: string;
  error?: string;
  logo?: string;
  content?: ContentData;
  onClickRequestNewCode: () => void;
  onClickVerifyOTP: () => void;
  onChangeOTP: (event: string) => void;
}
const OTPContainer = ({
  email,
  otp,
  error,
  logo,
  content,
  onChangeOTP,
  onClickVerifyOTP,
  onClickRequestNewCode,
}: OTPContainerProps) => {
  const theme = useTheme();

  return (
    <Box>
      <ModalContentContainer
        image={ logo }
        title={ content?.title || 'Connect to Mojito' }
        description={ `Please enter the code sent to <br/><b>${ email }</b>` } />
      <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>Please check your spam folder if you haven&rsquo;t received the email.</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <MuiOtpInput
          inputMode="numeric"
          TextFieldsProps={{ error: Boolean(error), inputMode: 'numeric', type: 'number' }}
          sx={{
            maxWidth: { sm: '100%', lg: '400px' },
            marginBottom: '5px',
            marginTop: '15px',
          }}
          gap={ 1 }
          length={ 6 }
          value={ otp }
          onChange={ onChangeOTP } />
      </Box>
      { error && (
        <Typography
          sx={{
            color: theme?.palette.error.dark,
            fontWeight: 400,
            fontSize: '14px',
          }}>
          { error }
        </Typography>
      ) }
      <Button
        data-testid="onClick_continue"
        sx={{ width: '100%', marginBottom: '15px', marginTop: '20px' }}
        onClick={ onClickVerifyOTP }
        disabled={ Boolean(error) || otp.length <= 5 }>
        Continue
      </Button>
      <Typography
        sx={{
          fontSize: '12px',
          textAlign: 'center',
          fontWeight: '700',
          cursor: 'pointer',
        }}
        data-testid="onClick_request_new_code"
        onClick={ onClickRequestNewCode }>
        Request new code
      </Typography>
    </Box>
  );
};
export default OTPContainer;
