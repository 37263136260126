import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import { WalletImages, WalletOptionsProps } from '../interface';
import Image from 'next/image';

export interface WalletListContainerProps {
    walletOptions: WalletOptionsProps;
    image: WalletImages;
    isMobile: boolean;
    onClickMetamask?: () => void;
    onClickWalletConnect?: () => void;
    onClickEmail?: () => void;
}

const WalletListContainer = ({
  walletOptions,
  image,
  isMobile,
  onClickMetamask,
  onClickWalletConnect,
  onClickEmail,
}:WalletListContainerProps) => {
  const theme = useTheme();
  const walletButtonStyle = useMemo(() => {
    return {
      width: '100%',
    };
  }, []);
  return (
    <>
      { walletOptions.enableMetamask && isMobile && (
      <Button
        data-testid="onClick_metamask"
        sx={{ ...walletButtonStyle, marginBottom: '16px' }}
        startIcon={ <Image unoptimized src={ image?.metamask ?? '' } width={ 16 } height={ 16 } alt="metamask" /> }
        onClick={ () => onClickMetamask?.() }>
        MetaMask
      </Button>
      ) }
      { walletOptions.enableWalletConnect && (
      <Button
        data-testid="onClick_wallet_connect"
        sx={{ ...walletButtonStyle, marginBottom: '16px' }}
        startIcon={ (
          <Image
            unoptimized
            src={ image?.walletConnect ?? '' }
            width={ 16 }
            height={ 16 }
            alt="wallet-connect" />
          ) }
        onClick={ () => onClickWalletConnect?.() }>
        WalletConnect
      </Button>
      ) }
      { walletOptions.enableEmail && (
      <Button
        data-testid="onClick_email_wallet"
        sx={{ ...walletButtonStyle, marginBottom: '30px' }}
        startIcon={ (
          <EmailIcon sx={{ color: theme.palette.text.primary }} />
          ) }
        onClick={ () => onClickEmail?.() }>
        Email Wallet
      </Button>
      ) }
    </>
  );
};

export default WalletListContainer;
