import { CryptoCurrenyCode } from "@graphql-generated";

export const isEmpty = (
  value: string | number | Array<any> | null | undefined
): boolean => {
  if (value === null || value === undefined) {
    return true;
  } else if (isString(value) && typeof value === "string") {
    return value.length === 0 || value.trim().length === 0;
  } else if (value instanceof Date) {
    return false;
  } else if (typeof value === "object") {
    return Object.keys(value).length === 0;
  } else if (Array.isArray(value)) {
    return value.length === 0;
  }
  return false;
};

export const isVideo = (url: string): boolean => {
  return /\.(mp4|ogv|webm|mov)$/.test(url);
}

export const isHtmlUrl = (url: string) => {
  return url?.includes('.html') || url?.includes('.htm');
}

export const getValidUrl = (url: string): string => {
  if (url?.includes('https')) {
    return url;
  } else {
    return `https:${url}`;
  }
}

export const isValidImageUrl = (url: string): boolean => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg|glb)(\?.*)?$/.test(url);
}

function isString(
  value: string | number | Array<any> | null | undefined
): boolean {
  if (value === null || value === undefined) {
    return false;
  } else if (typeof value === "string") {
    return true;
  }
  return false;
}
export const truncateAddress = (
  address: string | null | undefined,
  front = 6,
  back = 4
): string => {
  if (typeof address === "string" && address)
    return `${address?.substr(0, front)}...${address?.substr(
      address?.length - back
    )}`;
  return "";
};

export const getCurrency = (chainId: number) => {
  if (chainId === 1 || chainId === 5) {
    return CryptoCurrenyCode?.Eth
  } else if (chainId === 80001 || chainId === 137) {
    return CryptoCurrenyCode?.Matic
  } else {
    return CryptoCurrenyCode?.Eth
  }
}

export const getUsdPrice = (usdAmount: number, cryptoPrice: number) => {
  const price = usdAmount * cryptoPrice
  return truncateDecimal(price, 2);
}

export const roundUp = (num: number, precision: number): number => {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};

export const truncateDecimal = (
  number: string | number = "",
  index = 4,
  removeEmptydot = true
): string => {
  // convert number to string
  number += "";
  number = number.toString();
  // cutting the number.
  if (number.indexOf(".") > -1) {
    number = number.slice(0, number.indexOf(".") + (index + 1));
  }
  // If the their is no decimal remove the '.'
  if (removeEmptydot && number.indexOf(".") === number.length - 1) {
    number = number.slice(0, number.indexOf("."));
  }
  return number;
};

export function getChainExplore(
  chainId: number,
  contractAddress?: string,
  tokenId?: string,
  endPoint?: string
): string {
  if (chainId == 80001) {
    const url = tokenId ? `https://mumbai.polygonscan.com/token/${contractAddress}?a=${tokenId}` : `https://mumbai.polygonscan.com/${endPoint}/${contractAddress}`;
    return url
  } else if (chainId == 4) {
    const url = tokenId ? `https://rinkeby.etherscan.io/token/${contractAddress}?a=${tokenId}` : `https://rinkeby.etherscan.io/${endPoint}/${contractAddress}`;
    return url
  } else if (chainId == 1) {
    const url = tokenId ? `https://etherscan.io/token/${contractAddress}?a=${tokenId}` : `https://etherscan.io/${endPoint}/${contractAddress}`;
    return url
  } else if (chainId == 3) {
    const url = tokenId ? `https://ropsten.etherscan.io/token/${contractAddress}?a=${tokenId}` : `https://ropsten.etherscan.io/${endPoint}/${contractAddress}`;
    return url
  } else if (chainId == 5) {
    const url = tokenId ? `https://goerli.etherscan.io/token/${contractAddress}?a=${tokenId}` : `https://goerli.etherscan.io/${endPoint}/${contractAddress}`;
    return url
  } else if (chainId == 42) {
    const url = tokenId ? `https://kovan.etherscan.io/token/${contractAddress}?a=${tokenId}` : `https://kovan.etherscan.io/${endPoint}/${contractAddress}`;
    return url
  } else if (chainId == 137) {
    const url = tokenId ? `https://polygonscan.com/token/${contractAddress}?a=${tokenId}` : `https://polygonscan.com/${endPoint}/${contractAddress}`;
    return url
  } else if (chainId == 11155111) {
    const url = tokenId ? `https://sepolia.etherscan.io/token/${contractAddress}?a=${tokenId}` : `https://sepolia.etherscan.io/${endPoint}/${contractAddress}`;
    return url
  } else {
    const url = tokenId ? `https://etherscan.io/token/${contractAddress}?a=${tokenId}` : `https://etherscan.io/${endPoint}/${contractAddress}`;
    return url
  }
};

export const getChainExplorer = (
  chainId?: number,
): { name: string; url: string } => {
  switch (chainId) {
    case 1:
      return { name: 'Ethereum Mainnet', url: 'https://etherscan.io' };
    case 4:
      return { name: 'Rinkeby Testnet', url: 'https://rinkeby.etherscan.io' };
    case 137:
      return { name: 'Polygon Mainnet', url: 'https://polygonscan.com' };
    case 80001:
      return {
        name: 'Mumbai Testnet',
        url: 'https://mumbai.polygonscan.com',
      };
    case 5: return { name: 'Goerli Testnet', url: 'https://goerli.etherscan.io' };
    default:
      return { name: 'Ethereum Mainnet', url: 'https://etherscan.io' };
  }
};



