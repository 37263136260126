import Image from "next/image";
import logo from "../../../public/images/loading.gif";
import styles from "./Loader.module.css";

const LoaderComponent = () => {
  return (
    <div className={styles.paceLoader}>
      <Image alt="loader" width={ 180 } height={ 180 } style={{ width: '180px', height: '180px' }} src={logo} layout="fixed" objectFit="contain" unoptimized />
    </div>
  );
};

export default LoaderComponent;
