import ethIcon from './ethIcon.svg';
import logoIcon from './logo.png';
import metamask from './MetaMask.svg';
import walletConnect from './walletConnect.svg';
import wethIcon from './WETH.svg';
import maticIcon from './Matic.svg';

export const Images = {
  ETH_ICON: ethIcon,
  LOGO_ICON: logoIcon,
  METAMASK: metamask,
  WALLET_CONNECT: walletConnect,
  MATIC: maticIcon,
  WETH_ICON: wethIcon,
};
