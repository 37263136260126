import { useEffect, useState, useCallback } from "react";
import Footer from "@components/Footer/Footer";
import Navbar from "@components/navbarComponent/Navbar";
import LoaderComponent from "@components/Paceloader/Loader";
import Header from "@components/shared/Header";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThirdwebProvider, embeddedWallet } from '@thirdweb-dev/react';
import { Sepolia } from '@thirdweb-dev/chains';
import * as fbq from '../services/PixelTrack';
import { AuthProvider } from "@services/authProvider";
import { MojitoApiProvider } from "@services/mojitoApiProvider";
import { GlobalStyles } from "@styles/GlobalStyles";
import { theme } from "@styles/theme";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { QueryClientProvider } from "react-query";
import { queryClient } from "src/utils";
import { ThemeProvider } from "styled-components";
import "../localization";
import "../styles/fonts.css";
import Script from "next/script";
import mainstyle from "./main.module.css";
import { WalletContextProvider } from "@services/walletProvider";
import ConnectWalletLayout from "@layouts/connectWallet";
import { UserContextProvider } from "@services/UserProvider";
import { AuthDetailsProvider } from "src/provider/AuthTokenProvider";
import { WalletConnectProvider } from "src/connectWalletLib/provider/WalletConnectProvider";
import { Configuration } from "@constants";

function Loading() {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  const handleComplete = useCallback((url: any) => {
    if (
      url === router.asPath ||
      (router.asPath === "/kyc") ||
      url === "/profile" ||
      router.asPath?.includes("project") ||
      (url && typeof url === "string" && url?.includes("project"))
    ) {
      setTimeout(() => {
        setLoading(false);
      }, 5);
    }
  },[router.asPath]);

  const handleStart = useCallback((url: any) => url !== router.asPath && setLoading(true), [router.asPath]);

  useEffect(() => {
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [handleComplete, handleStart, router]);

  useEffect(() => {
    fbq.pageView();
    const handleRouteChange = () => {
      fbq.pageView();
    }
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return loading ? <LoaderComponent /> : <></>;
}
const GlobalStyleProxy: any = GlobalStyles;
const ThemeProviderProxy: any = ThemeProvider;
function MyApp({ Component, pageProps }: AppProps) {
  // var showBanner = localStorage.getItem('infoBanner');

  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=GTM-WGCG8VX"
        strategy="afterInteractive"
      />
      <Script src="https://cdn.checkout.com/js/framesv2.min.js" />
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
        __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${Configuration.META_PIXEL_ID});
          fbq('track', 'PageView');
        `,
        }}
      />
      <Script><img height="1" width="1" style={{ display: 'none' }} src={`https://www.facebook.com/tr?id=${Configuration.META_PIXEL_ID}&ev=PageView&noscript=1`} /></Script>
      <AuthProvider>
        <AuthDetailsProvider>
          <WalletContextProvider>
            <UserContextProvider>
              <MojitoApiProvider>
                <WalletConnectProvider>
                  <ThirdwebProvider
                    autoConnectTimeout={ 30000 }
                    clientId={ Configuration?.THIRD_WEB_CLIENT_ID }
                    activeChain={ Configuration?.THIRD_WEB_ACTIVE_CHAIN === 'sepolia' ? Sepolia : Configuration?.THIRD_WEB_ACTIVE_CHAIN }
                    supportedWallets={ [embeddedWallet()] }>
                    <Header title={"Pace Verso"} />
                    <Navbar />
                    <ConnectWalletLayout isMobile={false} />
                    <ThemeProviderProxy theme={theme}>
                      <div className={`${mainstyle.main_div} maincontWrpper`}>
                        <StyledEngineProvider injectFirst>
                          <GlobalStyleProxy />
                          <QueryClientProvider client={queryClient}>
                            <Loading />
                            <Component {...pageProps} />
                          </QueryClientProvider>
                        </StyledEngineProvider>
                      </div>
                    </ThemeProviderProxy>

                    <Footer />
                  </ThirdwebProvider>
                </WalletConnectProvider>
              </MojitoApiProvider>
            </UserContextProvider>
          </WalletContextProvider>
        </AuthDetailsProvider>
      </AuthProvider>
    </>
  );
}

export default MyApp;
