"use client";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetCurrentOrganizationUserQuery } from "@graphql-generated";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import { ThemeProvider } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { CoreServiceProvider } from '@mojito-inc/core-service';
import { useWeb3ModalProvider } from '@web3modal/ethers5/react';
import ButtonComponent from "@components/buttonComponent/Button";
import UnVerifiedImage from "../../../public/images/unVerifiedProfile.svg";
import VerifiedImage from "../../../public/images/verifiedProfile.svg";
import navsty from "./navbar.module.css";
import SearchComponent from "@components/SearchComponent/search";
import Context from "src/utils/connectContext";
import UserContext from "src/utils/userContext";
import ConnectWalletLayout, { WalletDetailsData } from '../../layouts/connectWallet/index';
import { clearStorage, LOCAL_STORAGE_STATE } from "src/constants/localStorage";
import LoaderComponent from "@components/Paceloader/Loader";
import { Configuration, SessionVariable } from "@constants";
import { Images } from "src/assets/images";
import { theme } from "src/theme";
import { ConnectWalletContainer } from "src/connectWalletLib";
import { useAuthDetails } from "src/provider/AuthTokenProvider";
import { getProvider } from "src/connectWalletLib/utils/getProvider.utils";
import { useSigner, useConnectionStatus } from "@thirdweb-dev/react";
import { WalletProviderType } from "src/connectWalletLib/constant";

const settings = ["Profile", "Logout"];

const Navbar = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, user, logout, getIdTokenClaims } =
    useAuth0();
  const router = useRouter();
  const { walletProvider } = useWeb3ModalProvider();
  const { connect, setConnect } = useContext(Context);
  const { setUserData } = useContext(UserContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [searchValue, setsearchValue] = React.useState("");
  const { authDetails, setAuthDetails } = useAuthDetails();
  const embeddedWalletSigner = useSigner();
  const connectionStatus = useConnectionStatus();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  
  const 
    {
      data: currentOrgdata,
      loading: currentOrgloading,
      error: currentOrgerror,
      refetch: getCurrentOrgUser,
    }= useGetCurrentOrganizationUserQuery({
    variables: {
      curOrg: Configuration.ORGANIZATION_ID,
    },
    skip: true
  });

  useEffect(() => {
    if (isAuthenticated || (router?.asPath?.includes('profile') && isAuthenticated)) {
      getCurrentOrgUser({
        curOrg: Configuration.ORGANIZATION_ID
      });
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    if (isAuthenticated && currentOrgdata?.me) {
      setUserData({
        id: currentOrgdata?.me?.userOrgs[0]?.id,
        kycStatus: currentOrgdata?.me?.userOrgs[0]?.kycStatus,
        reason: currentOrgdata?.me?.userOrgs[0]?.reason ?? '',
        username: currentOrgdata?.me?.userOrgs[0]?.username ?? ''
      })
    }
  }, [isAuthenticated, currentOrgdata?.me, setUserData]);

  const getAuthToken = async () => {
    setIsPageLoading(true);
    const bearerToken = await getIdTokenClaims();
    setAuthDetails((prev: any) => ({
      ...prev,
      token: bearerToken?.__raw ? `Bearer ${bearerToken?.__raw}` : undefined,
    }));
    setIsPageLoading(false);
  }

  useEffect(() => {
    if (isAuthenticated) {
      getAuthToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (connect?.onDisConnect) {
      setConnect(prev => ({
        ...prev,
        onDisConnect: false,
      }));
    }
  }, [connect, setConnect]);

  useEffect(() => {
    if (connect?.refetchBalance) {
      setConnect(prev => ({
        ...prev,
        refetchBalance: false,
      }));
    }
  }, [connect, setConnect]);

  const walletData = useMemo(() => {
    if (typeof window !== 'undefined') {
      const sessionData = sessionStorage.getItem(SessionVariable.WalletDetails);
      const response: WalletDetailsData = sessionData ? JSON.parse(sessionData ?? '') : null;
      return response;
    }
    return null;
  }, []);

  const retrieveWalletData = async () => {
    if (walletData) {
      const provider = await getProvider(walletData?.providerType, walletProvider, embeddedWalletSigner);
      setConnect(prev => ({
        ...prev,
        account: walletData.walletAddress,
        chainId: walletData?.networkDetails?.chainID,
        wethOrWmaticBalance: walletData?.balance?.nonNative?.toString(),
        ethOrMaticBalance: walletData?.balance?.native?.toString(),
        providerType: walletData?.providerType,
        networkDetails: {
          id: walletData?.networkDetails?.id,
          chainID: walletData?.networkDetails?.chainID,
          isTestnet: walletData?.networkDetails?.isTestnet,
          name: walletData?.networkDetails?.name,
        },
        provider: provider,
        connected: walletData?.walletAddress?.length > 0,
      }));
    }
  }

  useEffect(() => {
    if (walletData && (walletData?.providerType != WalletProviderType.EMAIL || (walletData?.providerType == WalletProviderType.EMAIL && connectionStatus === 'connected' && embeddedWalletSigner))) {
      retrieveWalletData();
    }
  }, [walletData, connectionStatus, embeddedWalletSigner]);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const login = async () => {
    if (router.asPath?.includes('nft/') || router.asPath?.includes('project/')) {
      localStorage.setItem("auth0Redirection", router.asPath);
    }
    localStorage.setItem("path", "fromLogin");
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
        origin: router.asPath,
      },
    });
  };

  const handleCloseUserMenu = (e: any) => {
    if (e?.target?.textContent === "Logout") {
      localStorage.removeItem("path");
      clearStorage(LOCAL_STORAGE_STATE?.secondary_wallet);
      sessionStorage.clear();
      logout({ returnTo: window.location.origin });
    } else if (e?.target?.textContent === "Profile") {
      router.push("/profile");
      setAnchorElUser(null);
    } else if (e?.target?.textContent === "Disconnect") {
      setConnect(prev => ({
        ...prev,
        onDisConnect: true,
      }));
      setAnchorElUser(null);
    } else {
      setAnchorElUser(null);
    }
  };

  const [searchPop, setSearchPop] = React.useState(false);
  const [mobMenu, setMobMenu] = React.useState(false);
  const toggleDrawer = () => {
    setSearchPop(!searchPop);
  };
  const toggleMobMenuDrawer = () => {
    setMobMenu(!mobMenu);
  };

  const isKycCheck = () => {
    if (currentOrgdata?.me?.userOrgs[0]) {
      if (currentOrgdata?.me?.userOrgs[0]?.kycStatus === "Clear") {
        return VerifiedImage
      } else {
        return UnVerifiedImage
      }
    } else {
      return UnVerifiedImage
    }
  }

  const onCloseWalletModal = useCallback(() => {
    setConnect(prev => ({
      ...prev,
      openMetaMask: false,
    }));
  }, [setConnect]);

  const onChangeWalletAddress = (event: WalletDetailsData) => {
    setConnect(prev => ({
      ...prev,
      account: event.walletAddress,
      chainId: event?.networkDetails?.chainID,
      wethOrWmaticBalance: event.balance.nonNative?.toString(),
      ethOrMaticBalance: event?.balance?.native?.toString(),
      providerType: event.providerType,
      networkDetails: {
        id: event.networkDetails.id,
        chainID: event.networkDetails.chainID,
        isTestnet: event.networkDetails.isTestnet,
        name: event.networkDetails.name,
      },
      provider: event?.provider,
      connected: event.walletAddress?.length > 0,
    }));
  };

  return (
    <>
      <Drawer
        anchor={"top"}
        open={mobMenu}
        onClose={toggleMobMenuDrawer}
        className="drawer-bg"
      >
        <div className={navsty.mobMenu}>
          <div className={navsty.close}>
            <span className="cursor-p" onClick={toggleMobMenuDrawer}>
              <CloseI />
            </span>
          </div>
          <div className={navsty.searchwrp}>
            <SearchComponent
              white={true}
              onchange={(e: any) => {
                setsearchValue(e);
              }}
              onClickSearch={() => {
                router.push({
                  pathname: "/project",
                  query: { search: searchValue },
                });
                toggleDrawer();
              }}
            />
          </div>
          <div>
            <Link href={"/project"}>
              <p>Projects</p>
            </Link>
          </div>
        </div>
      </Drawer>

      <Drawer
        anchor={"top"}
        open={searchPop}
        onClose={toggleDrawer}
        className="drawer-bg"
      >
        <div className={navsty.searchweb_wrp}>
          <div className={navsty.headwrp}>
            <div className={navsty.log}>
              <DrawerLogoI />
            </div>
            <div className={navsty.close}>
              <span className="cursor-p" onClick={toggleDrawer}>
                <CloseI />
              </span>
            </div>
          </div>
          <div className={navsty.searchwrp}>
            <SearchComponent
              white={true}
              onchange={(e: any) => {
                setsearchValue(e);
              }}
              onClickSearch={() => {
                router.push({
                  pathname: "/project",
                  query: { search: searchValue },
                });
                toggleDrawer();
              }}
            />
          </div>
        </div>
      </Drawer>
      <AppBar position="static" className={`${navsty.navbar}`}>
        <Toolbar disableGutters className={`${navsty.nav_wrp}`}>
          <Box className={navsty.icon_wrp}>
            <div>
              <span className="cursor-p" onClick={toggleMobMenuDrawer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 6.75H22V5.25H0V6.75ZM0 16.75H22V15.25H0V16.75Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </Box>
          <Box className={navsty.logo_wrp}>
            <div>
              {/* <Link href={"https://www.pacegallery.com/pace-verso/"}> */}
              <LogoI />
              {/* </Link> */}
            </div>
          </Box>
          <Box className={navsty.mnu_wrp}>
            <div>
              <ul className={`${navsty.ul}`}>
                <li className={navsty.nomob}>
                  <Link href={"/project"}>
                    <p className="cursor-p underline">Projects</p>
                  </Link>
                </li>
                <li>
                  {!isAuthenticated ? (
                    <Box className="login-btn">
                      <ButtonComponent
                        text="login"
                        variant="outlined"
                        cls="primary"
                        handleClick={login}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItem: "center",
                      }}
                    >
                      <ConnectWalletLayout isMobile />
                      <div onClick={handleOpenUserMenu} style={{ cursor: 'pointer' }}>
                        <Image
                          alt="kyc"
                          width={ 60 }
                          height={ 50 }
                          style={{ width: '60px', height: '50px' }}
                          src={isKycCheck()}
                          unoptimized
                        />
                      </div>
                      {/* <span
                        onClick={handleOpenUserMenu}
                        className={navsty.profile}
                      >
                      </span> */}
                    </Box>
                  )}
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    className="box-shadow"
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Box className={navsty.menu_list}>
                      {settings?.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={(e) => handleCloseUserMenu(e)}
                        >
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Box>
                  </Menu>
                </li>
                <li className={navsty.nomob}>
                  <span className="cursor-p" onClick={toggleDrawer}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="21"
                      viewBox="0 0 19 21"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.5 8.5C14.5 12.0899 11.5899 15 8 15C4.41015 15 1.5 12.0899 1.5 8.5C1.5 4.91015 4.41015 2 8 2C11.5899 2 14.5 4.91015 14.5 8.5ZM12.8629 14.8529C11.5152 15.886 9.82931 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 10.5472 15.231 12.4148 13.9662 13.8297L18.7491 18.2299L17.7335 19.3338L12.8629 14.8529Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
          </Box>
        </Toolbar>
        {
          authDetails.token && (
            <ThemeProvider theme={ theme }>
              <CoreServiceProvider uri={ Configuration.MOJITO_API_URL } token={ authDetails.token }>
                <ConnectWalletContainer
                  open={connect?.openMetaMask}
                  isWeb2Login
                  config={{
                    orgId: Configuration.ORGANIZATION_ID ?? '',
                    paperClientId: Configuration.PAPER_CLIENT_ID,
                    paperNetworkName: Configuration?.PAPER_NETWORK_NAME,
                    chainId: +Configuration?.CHAIN_ID ?? 1,
                    projectId: Configuration.PROJECT_ID,
                  }}
                  walletOptions={{
                    enableEmail: true,
                    enableMetamask: true,
                    enableWalletConnect: true,
                  }}
                  content={{
                    otpContentData: {
                      title: 'Connect to Pace Verso',
                    },
                    emailContentData: {
                      title: 'Connect to Pace Verso',
                    },
                  }}
                  image={{
                    logo: Images.LOGO_ICON?.src,
                    metamask: Images.METAMASK?.src,
                    walletConnect: Images.WALLET_CONNECT?.src,
                  }}
                  isDisConnect={connect?.onDisConnect}
                  walletAddress={connect?.account}
                  isRefetchBalance={connect?.refetchBalance}
                  onChangeWalletAddress={onChangeWalletAddress}
                  onCloseModal={onCloseWalletModal} />
              </CoreServiceProvider>
            </ThemeProvider>
          )
        }
      </AppBar>
      {(currentOrgloading || isLoading || isPageLoading) && <LoaderComponent />}
    </>
  );
};
export default Navbar;

function DrawerLogoI() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="34"
      viewBox="0 0 84 34"
      fill="none"
    >
      <g clip-path="url(#clip0_916_876)">
        <path
          d="M15.6135 19.0095V23.2046H12.6272V10.7744H18.3019C21.4669 10.7744 23.2694 12.186 23.2694 14.9014C23.2694 17.6168 21.4669 19.0095 18.3365 19.0095H15.6135ZM17.9791 16.6934C19.5702 16.6934 20.2601 16.067 20.2601 14.9014C20.2601 13.6998 19.5702 13.0905 17.9791 13.0905H15.6135V16.6858L17.9791 16.6934Z"
          fill="white"
        />
        <path
          d="M36.1389 20.5403H30.8005L29.757 23.2046H26.7708L31.7902 10.7744H35.2376L40.2705 23.2046H37.1478L36.1389 20.5403ZM31.6845 18.2431H35.2741L33.5235 13.6469H33.4697L31.6845 18.2431Z"
          fill="white"
        />
        <path
          d="M50.5303 21.0987C52.0676 21.0987 52.8113 20.2453 53.0419 19.0437H56.0109C55.5151 21.6209 53.7664 23.4129 50.5303 23.4129C46.7658 23.4129 44.3425 20.7846 44.3425 16.9906C44.3425 13.1966 46.7658 10.5664 50.5303 10.5664C53.7664 10.5664 55.5151 12.3413 56.0109 14.9356H53.0419C52.8113 13.734 52.0696 12.8806 50.5303 12.8806C48.5337 12.8806 47.4556 14.4134 47.4556 16.9906C47.4556 19.5679 48.5337 21.0987 50.5303 21.0987Z"
          fill="white"
        />
        <path
          d="M62.0046 23.2046V10.7744H71.5515V13.0905H64.9928V15.8059H70.7732V18.122H64.9928V20.8715H71.6745V23.2046H62.0046Z"
          fill="white"
        />
        <path
          d="M84 34H0V0H84V34ZM2.306 31.7293H81.694V2.2707H2.306V31.7293Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_916_876">
          <rect width="84" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
function LogoI() {
  const router = useRouter();

  return (
    <div style={{ cursor: 'pointer', }} onClick={() => { router.push('/') }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="30"
        viewBox="0 0 150 30"
        fill="none"
      >
        <rect width="150" height="30" fill="white" />
        <path
          d="M15.7718 14.7494C17.0774 14.7494 17.6435 14.2274 17.6435 13.2564C17.6435 12.2854 17.0774 11.7482 15.7718 11.7482H13.827V14.7494H15.7718ZM13.827 16.6773V20.1711H11.375V9.82031H16.0324C18.6295 9.82031 20.1096 10.9951 20.1096 13.2564C20.1096 15.5177 18.6295 16.6773 16.0608 16.6773H13.8259H13.827Z"
          fill="#231F20"
        />
        <path
          d="M27.394 16.0398H30.339L28.9025 12.2124H28.8589L27.394 16.0398ZM31.0502 17.9534H26.6676L25.8114 20.1711H23.3594L27.4802 9.82031H30.3096L34.4445 20.1711H31.8759L31.0491 17.9534H31.0502Z"
          fill="#231F20"
        />
        <path
          d="M43.2816 18.4124C44.5436 18.4124 45.1533 17.7018 45.342 16.7014H47.7798C47.374 18.8472 45.9375 20.3402 43.2816 20.3402C40.1915 20.3402 38.2031 18.1508 38.2031 14.9904C38.2031 11.83 40.1915 9.64062 43.2816 9.64062C45.9365 9.64062 47.373 11.1195 47.7798 13.2794H45.342C45.1533 12.279 44.5436 11.5685 43.2816 11.5685C41.6422 11.5685 40.7565 12.8446 40.7565 14.9893C40.7565 17.134 41.6411 18.4102 43.2816 18.4102"
          fill="#231F20"
        />
        <path
          d="M53.6641 20.1711V9.82031H61.4999V11.7482H56.1171V14.0095H61.1705V15.9384H56.1171V18.2291H61.4999V20.1711H53.6641Z"
          fill="#231F20"
        />
        <path
          d="M3.05059 3.04881H69.8255V26.9523H3.05059V3.04881ZM1 29H71.8761V1H1V29Z"
          fill="#231F20"
        />
        <path
          d="M80.4219 9.8125H83.092L85.8636 17.4236H85.893L88.6504 9.8125H91.2333L87.2139 20.1633H84.4282L80.423 9.8125H80.4219Z"
          fill="#231F20"
        />
        <path
          d="M96.2667 20.1633V9.8125H104.101V11.7403H98.7187V14.0017H103.773V15.9295H98.7187V18.2202H104.101V20.1633H96.2656H96.2667Z"
          fill="#231F20"
        />
        <path
          d="M110.454 20.1633V9.8125H115.271C117.796 9.8125 119.304 10.972 119.304 13.205C119.304 14.7416 118.405 15.7279 117.258 16.191L119.638 20.1633H116.94L114.85 16.5823H112.905V20.1633H110.453H110.454ZM114.952 14.6544C116.273 14.6544 116.838 14.1619 116.838 13.205C116.838 12.2482 116.272 11.7403 114.952 11.7403H112.906V14.6544H114.952Z"
          fill="#231F20"
        />
        <path
          d="M126.87 16.976C126.986 18.165 128.06 18.542 129.147 18.542C130.163 18.542 131.019 18.1366 131.019 17.2812C131.019 16.6284 130.598 16.3243 129.467 16.1358L127.639 15.8023C126.014 15.5124 124.766 14.7147 124.766 12.874C124.766 10.9168 126.536 9.64062 128.901 9.64062C131.571 9.64062 133.095 10.8154 133.298 12.8152H130.933C130.846 11.9161 130.063 11.4377 128.93 11.4377C127.9 11.4377 127.218 11.9455 127.218 12.6844C127.218 13.4233 127.726 13.5977 128.712 13.7862L130.685 14.1633C132.485 14.4826 133.485 15.41 133.485 17.1057C133.485 19.1066 131.628 20.3391 129.234 20.3391C126.579 20.3391 124.663 19.2515 124.445 16.976H126.869H126.87Z"
          fill="#231F20"
        />
        <path
          d="M143.854 20.3402C140.647 20.3402 138.703 18.093 138.703 14.9904C138.703 11.8878 140.648 9.64062 143.854 9.64062C147.059 9.64062 149.005 11.8878 149.005 14.9904C149.005 18.093 147.046 20.3402 143.854 20.3402ZM143.854 11.5696C142.127 11.5696 141.257 12.874 141.257 14.9904C141.257 17.1068 142.127 18.4124 143.854 18.4124C145.58 18.4124 146.451 17.1079 146.451 14.9904C146.451 12.8729 145.58 11.5696 143.854 11.5696Z"
          fill="#231F20"
        />
      </svg>
    </div>
  );
}
function CloseI() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7448 11.7906L17.5346 18.5687L18.5962 17.509L11.8063 10.7309L18.5962 3.95281L17.5346 2.89307L10.7448 9.67115L3.9295 2.86768L2.86792 3.92742L9.68317 10.7309L2.86792 17.5343L3.9295 18.5941L10.7448 11.7906Z"
        fill="white"
      />
    </svg>
  );
}

