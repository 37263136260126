import { ethers } from 'ethers';
import { WalletProviderType } from '../constant';

export const getProvider = async (providerType: string, walletProvider: any, embeddedWalletSigner: any) => {
  if (providerType === WalletProviderType.EMAIL) {
    return embeddedWalletSigner;
  }
  if (providerType === WalletProviderType.WALLET_CONNECT && walletProvider) {
    return new ethers.providers.Web3Provider(walletProvider);
  }
  return new ethers.providers.Web3Provider(window.ethereum);
};
