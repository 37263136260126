import { Network, Price } from "@interface/wallet.interface";
import { createContext, useContext } from "react";

export interface ConnectType {
  refetchBalance: boolean;
  connected?: boolean;
  chainId?: number | null;
  provider?: any;
  account: string;
  wethOrWmaticBalance?: string | null;
  ethOrMaticBalance?: string | null;
  currency?: string | null;
  price?: Price;
  openMetaMask: boolean;
  onDisConnect: boolean;
  networkDetails?: Network | null;
  providerType?: string;
}
export interface ContextType {
  connect: ConnectType;
  setConnect(f: ConnectType | ((prev: ConnectType) => ConnectType)): void;
}
const Context = createContext<ContextType>({} as ContextType);
export default Context;

export function useWeb3(): ContextType {
  return useContext(Context);
}
