"use client";

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ethers } from 'ethers';
import { useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal, useDisconnect } from '@web3modal/ethers5/react';
import {
  useAddress,
  useConnectionStatus,
  useDisconnect as useThirdWebDisconnect,
  useEmbeddedWallet,
  useChainId,
  useSigner,
} from '@thirdweb-dev/react';
import { useConnectWallet } from '@mojito-inc/core-service';
import { ConnectWalletLayout } from '../layout/index';
import { BalanceData, ConnectWalletProps, ModalType, SupportedNetworksData, WalletDetailsData } from '../interface';
import { emailRegex, ErrorsType, SessionVariable, WalletProviderType } from '../constant';
import { session } from '../utils/sessionStorage.utils';
import useWalletConnect from '../hooks/useConnectWallet';
import { getBalance } from '../utils/getBalance.utils';
import { getProvider } from '../utils/getProvider.utils';

const ConnectWalletContainer = ({
  open,
  isWeb2Login,
  walletOptions,
  config,
  isDisConnect,
  image,
  content,
  isRefetchBalance,
  appMetaData,
  skipSignature,
  onChangeWalletAddress,
  onCloseModal,
}: ConnectWalletProps) => {

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();

  const { getSignature, loginWithWallet, isTokenGating } = useWalletConnect({ orgId: config?.orgId });
  const { connectExternalWallet } = useConnectWallet();
  const { open: openWalletModal, close: closeWalletModal } = useWeb3Modal();
  const walletDetails = session(SessionVariable.WalletDetails, true);

  const [loaderTitle, setLoaderTitle] = useState<string>('');
  const [modalState, setModalState] = useState(
    ModalType.CONNECT_WALLET,
  );
  const thirdWebConnect = useRef<null | boolean>(null);
  const connectedEmailWalletAddress = useAddress();
  const connectionStatus = useConnectionStatus();
  const thirdWebDisconnect = useThirdWebDisconnect();
  const thirdWebConnectedChain = useChainId();
  const embeddedWalletSigner = useSigner();
  const { connect, sendVerificationEmail } = useEmbeddedWallet();

  const [error, setError] = useState('');
  const [email, setEmail] = useState<string>('');
  const [otp, setOTP] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [newDevice, setIsNewDevice] = useState<boolean>(false);
  const [newUser, setIsNewUser] = useState<boolean>(false);
  const [recoveryCode, setRecoveryCode] = useState<string>('');
  const [isAuthToken, setIsAuthToken] = useState<boolean>(false);
  const [connectParam, setConnectParam] = useState({
    signatureMessage: '',
    walletAddress: '',
    signature: '',
    networkId: '',
  });
  const [wallet, setWalletData] = useState<WalletDetailsData>();

  const [walletConnect, setWalletConnect] = useState<boolean>(false);

  const onDisConnect = useCallback(async () => {
    setOTP('');
    setEmail('');
    setRecoveryCode('');
    setIsNewDevice(false);
    setLoaderTitle('');
    setError('');
    setModalState(ModalType.CONNECT_WALLET);
    if (typeof window !== 'undefined') {
      window.sessionStorage.removeItem(SessionVariable.WalletDetails);
    }
    if (walletDetails?.providerType === WalletProviderType.WALLET_CONNECT && isConnected) {
      disconnect();
    }
    if (walletDetails?.providerType === WalletProviderType.EMAIL && connectionStatus === 'connected') {
      await thirdWebDisconnect();
    }
  }, [isConnected, walletDetails, disconnect, thirdWebDisconnect]);

  const fetchBalance = useCallback(async () => {
    if (walletDetails?.walletAddress) {
      const provider = await getProvider(walletDetails?.providerType, walletProvider, embeddedWalletSigner);
      const balance = await getBalance(provider, walletDetails?.walletAddress, walletDetails?.networkDetails?.chainID, walletDetails?.providerType);
      const walletData: WalletDetailsData = {
        walletAddress: walletDetails?.walletAddress,
        providerType: walletDetails?.providerType,
        networkDetails: {
          id: walletDetails?.networkDetails?.id,
          chainID: walletDetails?.networkDetails?.chainID,
          name: walletDetails?.networkDetails?.name,
          isTestnet: walletDetails?.networkDetails?.isTestnet,
        },
        balance: {
          native: balance?.native ?? 0,
          nonNative: balance?.nonNative ?? 0,
        },
        provider: walletDetails?.provider,
      };
      setWalletData(walletData);
      session(SessionVariable.WalletDetails, false, walletData);
      if (onChangeWalletAddress) {
        onChangeWalletAddress(walletData);
      }
    }
  }, [walletDetails, walletProvider, onChangeWalletAddress, embeddedWalletSigner]);

  useEffect(() => {
    if (isDisConnect) {
      onDisConnect();
    }
  }, [isDisConnect, onDisConnect]);

  useEffect(() => {
    if (isRefetchBalance && (walletDetails?.providerType != WalletProviderType.EMAIL || (walletDetails?.providerType == WalletProviderType.EMAIL && connectionStatus === 'connected' && embeddedWalletSigner))) {
      fetchBalance();
    }
  }, [isRefetchBalance, fetchBalance, walletDetails, connectionStatus, embeddedWalletSigner]);

  const getNetworkDetailsByChainId = useCallback((networkData: SupportedNetworksData[], chainId: number) => {
    const response = networkData.filter((item: SupportedNetworksData) => item.chainID === chainId);
    return response?.[0];
  }, []);

  const connectWallet = useCallback(async () => {
    try {
      const walletResponse = await connectExternalWallet({
        address: connectParam.walletAddress,
        message: connectParam.signatureMessage,
        signature: connectParam.signature,
        orgID: config.orgId,
        networkID: connectParam.networkId,
      });
      const updateProvider = {
        ...wallet, provider: null,
      };
      session(SessionVariable.WalletDetails, false, updateProvider);
      if (onChangeWalletAddress && wallet) {
        onChangeWalletAddress(wallet);
      }
      setModalState(ModalType.CONNECT_WALLET);
      setLoaderTitle('');
      setIsNewDevice(false);
      setOpenModal(false);
      onCloseModal(true);
      return { status: true, message: walletResponse?.data?.connectExternalWallet };
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      if (e?.message?.includes(ErrorsType.WRONG_WALLET)) {
        setError('For compliance & security reasons, we are allowing only one active wallet for a user. Please disconnect your wallet on another account and retry or contact support.');
      } else if (e?.message?.includes(ErrorsType.HIGH_RISK)) {
        setError('You are not permitted to complete this transaction.');
      } else {
        setError('We are unable to connect your wallet');
      }
      return { status: false, message: e?.message };
    }
  }, [config, connectParam, wallet, connectExternalWallet, onCloseModal, onChangeWalletAddress]);

  useEffect(() => {
    if (isAuthToken) {
      connectWallet();
      setIsAuthToken(false);
    }
  }, [isAuthToken, connectWallet]);

  const getSignatureData = useCallback(async (provider: any, message: string, providerType: string) => {
    try {
      if (providerType === WalletProviderType.EMAIL) {
        const signature = await provider.signMessage(message);
        return {
          status: true,
          message: signature,
        };
      }
      const signer = provider?.getSigner();
      const signature = await signer?.signMessage(message);
      return {
        status: true,
        message: signature,
      };
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      if (typeof window !== 'undefined') {
        window.sessionStorage.removeItem(SessionVariable.WalletDetails);
        onChangeWalletAddress?.({
          walletAddress: '',
          balance: {
            native: 0,
            nonNative: 0,
          },
          networkDetails: {
            chainID: 0,
            id: '',
            isTestnet: false,
            name: '',
          },
          providerType: '',
          provider: null,
        });
      }
      if (e?.message?.includes('user rejected') || e?.message?.includes('user denied')) {
        setError('Transaction has been cancelled by user. Please try again.');
        return {
          status: false,
          message: 'Transaction has been cancelled by user. Please try again.',
        };
      }
      setError('Something went wrong');
      return {
        status: false,
        message: 'Something went wrong',
      };
    }
  }, [onChangeWalletAddress]);

  const getSignatureMessage = useCallback(
    async (
      connectedAddress: string,
      provider: any,
      chainId: number,
      providerType: string,
      balance: BalanceData,
    ) => {
      try {
        setConnectParam({
          signatureMessage: '',
          walletAddress: '',
          signature: '',
          networkId: '',
        });
        const networkData = session(SessionVariable.NetworkDetails, true);
        if (chainId && networkData) {
          const networkDetails = getNetworkDetailsByChainId(networkData, chainId);
          if (networkDetails?.id) {
            const signatureMsg = await getSignature(
              connectedAddress,
              networkDetails.id,
            );
            if (signatureMsg?.status) {
              const signature = await getSignatureData(
                provider,
                signatureMsg.message,
                providerType,
              );
              if (signature?.status) {
                if (isWeb2Login) {
                  const walletData: WalletDetailsData = {
                    walletAddress: connectedAddress,
                    networkDetails,
                    providerType: providerType ?? '',
                    balance: {
                      native: balance?.native ?? 0,
                      nonNative: balance?.nonNative ?? 0,
                    },
                    provider: provider,
                  };
                  setWalletData(walletData);
                  setConnectParam({
                    networkId: networkDetails.id,
                    signature: signature.message,
                    signatureMessage: signatureMsg.message,
                    walletAddress: connectedAddress,
                  });
                  setIsAuthToken(true);
                  return;
                }
                const walletResponse = await loginWithWallet(
                  signatureMsg.message,
                  connectedAddress,
                  signature?.message,
                  chainId,
                );
                const walletData: WalletDetailsData = {
                  walletAddress: connectedAddress,
                  networkDetails,
                  providerType: providerType ?? '',
                  balance: {
                    native: balance?.native ?? 0,
                    nonNative: balance?.nonNative ?? 0,
                  },
                  provider:
                    providerType === WalletProviderType.EMAIL ? provider : null,
                };
                setWalletData(walletData);
                if (walletResponse?.status) {
                  session(
                    SessionVariable.AuthToken,
                    false,
                    walletResponse.message,
                  );
                  setConnectParam({
                    networkId: networkDetails.id,
                    signature: signature?.message,
                    signatureMessage: signatureMsg.message,
                    walletAddress: connectedAddress,
                  });
                  setIsAuthToken(true);
                }
                return;
              }
              setLoaderTitle('');
              setModalState(ModalType.ERROR);
              return;
            }
            setLoaderTitle('');
            setModalState(ModalType.ERROR);
          } else {
            setModalState(ModalType.ERROR);
            setError(
              `Invalid network detected. Please Change to valid network (${ String(
                networkData.map((item: SupportedNetworksData) => item.name),
              ).replaceAll(',', ', ') })`,
            );
          }
        }
      } catch (e: any) {
        setLoaderTitle('');
        setModalState(ModalType.ERROR);
        if (
          e?.message?.includes('user rejected') ||
          e?.message?.includes('user denied')
        ) {
          setError('Transaction has been cancelled by user. Please try again.');
        } else {
          setError('Something went wrong');
        }
      }
    },
    [getSignature, loginWithWallet, getNetworkDetailsByChainId, getSignatureData, isWeb2Login],
  );

  const connectionSuccess = useCallback((connectedAddress: string, provider: any, chainId: number, providerType: string, balance: BalanceData) => {
    try {
      const networkData = session(SessionVariable.NetworkDetails, true);
      const networkDetails = getNetworkDetailsByChainId(networkData, chainId);
      const walletData: WalletDetailsData = {
        walletAddress: connectedAddress,
        networkDetails,
        providerType: providerType ?? '',
        balance: {
          native: balance?.native ?? 0,
          nonNative: balance?.nonNative ?? 0,
        },
        provider,
      };
      const sessionData: WalletDetailsData = {
        walletAddress: connectedAddress,
        networkDetails,
        providerType: providerType ?? '',
        balance: {
          native: balance?.native ?? 0,
          nonNative: balance?.nonNative ?? 0,
        },
        provider: null,
      };
      setWalletData(walletData);
      if (onChangeWalletAddress && walletData?.walletAddress) {
        onChangeWalletAddress(walletData);
        session(SessionVariable.WalletDetails, false, sessionData);
      }
      setModalState(ModalType.CONNECT_WALLET);
      setLoaderTitle('');
      setIsNewDevice(false);
      setOpenModal(false);
      onCloseModal(true);
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [getNetworkDetailsByChainId, onChangeWalletAddress, onCloseModal]);

  const verifyOTP = useCallback(async () => {
    try {
      setLoaderTitle('Processing...');
      setModalState(ModalType.LOADING);
      await connect({ strategy: 'email_verification', email, verificationCode: otp });
      thirdWebConnect.current = true;
    } catch (e: any) {
      thirdWebConnect.current = null;
      setModalState(ModalType.OTP);
      setError('Code is incorrect');
    }
  }, [skipSignature, newDevice, email, otp, recoveryCode, newUser, connectionSuccess, getSignatureMessage]);

  const onClickMetamask = useCallback(async () => {
    try {
      if (!isTokenGating) {
        setOpenModal(true);
        onChangeWalletAddress?.({
          walletAddress: '',
          balance: {
            native: 0,
            nonNative: 0,
          },
          networkDetails: {
            chainID: 0,
            id: '',
            isTestnet: false,
            name: '',
          },
          providerType: WalletProviderType.METAMASK,
          provider: null,
        });
        if (window && window.ethereum) {
          setLoaderTitle('Connecting wallet...');
          setModalState(ModalType.LOADING);
          const isAccount = async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const accounts = await provider.listAccounts();
            return accounts.length !== 0;
          };
          const accountStatus = await isAccount();
          if (!accountStatus) {
            const { ethereum } = window;
            await ethereum.request({ method: 'eth_requestAccounts' });
          }

          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const accounts = await provider.listAccounts();
          const { chainId } = await provider.getNetwork();
          const balance = await getBalance(provider, accounts?.[0], chainId, WalletProviderType.METAMASK);
          if (skipSignature) {
            connectionSuccess(accounts?.[0], provider, chainId, WalletProviderType.METAMASK, balance);
            return;
          }
          if (accounts?.[0]) {
            await getSignatureMessage(accounts?.[0], provider, chainId, WalletProviderType.METAMASK, balance);
          }
        } else {
          setLoaderTitle('');
          setModalState(ModalType.ERROR);
          setError('Meta mask not installed in your browser');
        }
      }
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [skipSignature, isTokenGating, onChangeWalletAddress, getSignatureMessage, connectionSuccess]);

  const onClickWalletConnect = useCallback(async () => {
    try {
      onCloseModal(true);
      setOpenModal(false);
      if (isConnected) {
        disconnect();
      }
      onChangeWalletAddress?.({
        walletAddress: '',
        balance: {
          native: 0,
          nonNative: 0,
        },
        networkDetails: {
          chainID: 0,
          id: '',
          isTestnet: false,
          name: '',
        },
        providerType: WalletProviderType.WALLET_CONNECT,
        provider: null,
      });
      openWalletModal({ view: 'Connect' });
      setWalletConnect(true);
    } catch (e: any) {
      setWalletConnect(false);
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [openWalletModal, onCloseModal, onChangeWalletAddress, disconnect]);

  const onWalletConnect = useCallback(async (connectedAddress: string, connectedChainId: number) => {
    try {
      closeWalletModal();
      setWalletConnect(false);
      setOpenModal(true);
      setLoaderTitle('Connecting wallet...');
      setModalState(ModalType.LOADING);
      if (walletProvider && connectedAddress && connectedChainId) {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const balance = await getBalance(provider, connectedAddress, connectedChainId, WalletProviderType.WALLET_CONNECT);
        if (skipSignature) {
          connectionSuccess(
            connectedAddress,
            provider,
            connectedChainId,
            WalletProviderType.WALLET_CONNECT,
            balance,
          );
          return;
        }
        await getSignatureMessage(
          connectedAddress,
          provider,
          connectedChainId,
          WalletProviderType.WALLET_CONNECT,
          balance,
        );
      } else {
        setLoaderTitle('');
        setModalState(ModalType.ERROR);
        setError('Something wrong');
      }
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [walletProvider, skipSignature, getSignatureMessage, connectionSuccess, closeWalletModal]);

  useEffect(() => {
    if (isConnected && address && chainId && walletConnect && walletProvider) {
      onWalletConnect(address, chainId);
      setWalletConnect(false);
    }
  }, [isConnected, address, chainId, walletConnect, walletProvider, onWalletConnect]);

  useEffect(() => {
    if (walletDetails?.providerType === WalletProviderType.METAMASK) {
      window.ethereum?.on('accountsChanged', onClickMetamask);
      window.ethereum?.on('chainChanged', onClickMetamask);
      return () => {
        window.ethereum?.removeListener('chainChanged', onClickMetamask);
        window.ethereum?.removeListener('accountsChanged', onClickMetamask);
      };
    }
    return undefined;
  }, [onClickMetamask, walletDetails?.providerType]);

  useEffect(() => {
    if (open) {
      setError('');
      setModalState(ModalType.CONNECT_WALLET);
      setLoaderTitle('');
      setOTP('');
      setEmail('');
      setRecoveryCode('');
    }
  }, [open]);

  const onChangeOTP = useCallback(
    (event: string) => {
      setError('');
      setOTP(event);
    },
    [],
  );

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError('');
      setEmail(event.target.value);
    },
    [],
  );

  const onChangeRecoveryCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError('');
      setRecoveryCode(event.target.value);
    },
    [],
  );

  const onClickContinueWithEmail = useCallback(async () => {
    try {
      setOpenModal(true);
      if (!email) {
        setError('Email is required');
        return;
      }
      if (emailRegex.test(email)) {
        onChangeWalletAddress?.({
          walletAddress: '',
          balance: {
            native: 0,
            nonNative: 0,
          },
          networkDetails: {
            chainID: 0,
            id: '',
            isTestnet: false,
            name: '',
          },
          providerType: WalletProviderType.EMAIL,
          provider: null,
        });
        setError('');
        setLoaderTitle('Processing...');
        setModalState(ModalType.LOADING);
        if (connectionStatus === 'connected') {
          await thirdWebDisconnect();
        }
        await sendVerificationEmail({ email });
        setModalState(ModalType.OTP);
      } else {
        setError('Please enter a valid email');
      }
    } catch (e: any) {
      setLoaderTitle('');
      setModalState(ModalType.OTP);
      setError(e?.message);
    }
  }, [email, connectionStatus,  onChangeWalletAddress]);

  const handleRetry = useCallback(() => {
    setError('');
    setModalState(ModalType.CONNECT_WALLET);
  }, []);

  const onClickRecoveryCode = useCallback(() => {
    if (!recoveryCode) {
      setError('Recovery code is required');
      return;
    }
    setModalState(ModalType.OTP);
  }, [recoveryCode]);

  const handleCloseModal = useCallback(() => {
    setModalState(ModalType.CONNECT_WALLET);
    setError('');
    setOpenModal(false);
    onCloseModal();
  }, [onCloseModal]);

  const onClickEmail = useCallback(() => {
    setModalState(ModalType.EMAIL);
  }, []);

  const onConnectEmailWallet = useCallback(async (connectedAddress: string, connectedChainId: number, provider: any) => {
    try {
      thirdWebConnect.current = null;
      setModalState(ModalType.LOADING);
      setLoaderTitle('Connecting wallet...');
      if (connectedAddress && connectedChainId) {
        const balance = await getBalance(provider, connectedAddress, connectedChainId, WalletProviderType.EMAIL);
        if (skipSignature) {
          connectionSuccess(
            connectedAddress,
            provider,
            connectedChainId,
            WalletProviderType.WALLET_CONNECT,
            balance,
          );
          return;
        }
        await getSignatureMessage(
          connectedAddress,
          provider,
          connectedChainId,
          WalletProviderType.EMAIL,
          balance,
        );
      } else {
        setModalState(ModalType.ERROR);
        setError('Something wrong');
      }
    } catch (e: any) {
      setModalState(ModalType.ERROR);
      setError(e?.message);
    }
  }, [skipSignature, connectionSuccess, getSignatureMessage]);


  useEffect(() => {
    if (connectionStatus === 'connected' && thirdWebConnectedChain && connectedEmailWalletAddress && thirdWebConnect.current && embeddedWalletSigner) {
      onConnectEmailWallet(connectedEmailWalletAddress, thirdWebConnectedChain, embeddedWalletSigner);
    }
  }, [connectionStatus, thirdWebConnectedChain, connectedEmailWalletAddress, embeddedWalletSigner, onConnectEmailWallet]);

  return (
    <ConnectWalletLayout
      open={ open || openModal }
      error={ error }
      email={ email }
      otp={ otp }
      recoveryCode={ recoveryCode }
      image={ image }
      walletOptions={ walletOptions }
      loaderTitle={ loaderTitle }
      currentModalState={ modalState }
      content={ content }
      onChangeEmail={ onChangeEmail }
      onChangeOTP={ onChangeOTP }
      onChangeRecoveryCode={ onChangeRecoveryCode }
      onClickContinueWithEmail={ onClickContinueWithEmail }
      onClickEmail={ onClickEmail }
      onClickVerifyOTP={ verifyOTP }
      onClickMetamask={ onClickMetamask }
      onClickWalletConnect={ onClickWalletConnect }
      onClickRecoveryCode={ onClickRecoveryCode }
      handleRetry={ handleRetry }
      onCloseModal={ handleCloseModal } />
  );
};

export default ConnectWalletContainer;
