import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ModalContentContainer from './ModalContentContainer';
import { ContentData } from '../interface';

export interface ConnectWithEmailContainerProps {
  email: string;
  error?: string;
  logoUrl?: string;
  content?: ContentData;
  onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickContinueWithEmail: () => void;
}

const ConnectWithEmailContainer = ({
  email,
  error,
  logoUrl,
  content,
  onChangeEmail,
  onClickContinueWithEmail,
}: ConnectWithEmailContainerProps) => {
  const theme = useTheme();

  return (
    <Box>
      <ModalContentContainer
        image={ logoUrl }
        title={ content?.title || 'Connect to Mojito' } />
      <TextField
        sx={{ width: '100%', marginBottom: '15px' }}
        InputProps={{
          sx: {
            height: '40px',
            color: theme?.palette?.common?.black,
          },
        }}
        value={ email }
        data-testid="onChange_email"
        placeholder="name@email.com"
        error={ !!error }
        onChange={ onChangeEmail }
        helperText={ error } />
      <Button
        data-testid="onClick_continue_with_email"
        sx={{ width: '100%', marginBottom: '20px' }}
        onClick={ onClickContinueWithEmail }>
        Continue
      </Button>
    </Box>
  );
};
export default ConnectWithEmailContainer;
