import { Colors, FontStyle } from "./theme.configuration";
import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  backgrounds: {},
  borderRadius: {
    small: "8px",
    medium: "16px",
    large: "24px",
    rounded: "1024px",
  },
  borders: {
    thin: (borderColor: string = theme.colors.text) =>
      `1px solid ${borderColor}`,
    medium: (borderColor: string = theme.colors.text) =>
      `2px solid ${borderColor}`,
  },
  colors: Colors,
  fonts: FontStyle,
  textDecoration: {
    link: "underline",
    seeMoreText: "underline",
  },
  zIndex: {
    info: 1,
    footerLogo: -1000,
  },
  breakpoints: {
    sm: 500,
    md: 767,
    lg: 1115,
    maxWidth: 1368,
  },
  up: (breakpoint: number) => `@media (min-width: ${breakpoint}px`,
  down: (breakpoint: number) => `@media (max-width: ${breakpoint}px)`,
};
