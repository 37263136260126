import React, { useState, ReactNode } from "react";
import ConnectContext, { ConnectType } from "../utils/connectContext";

interface LayoutProps {
  children: ReactNode;
}

export const WalletContextProvider = ({
  children,
}: LayoutProps): JSX.Element => {
  const [connect, setConnect] = useState<ConnectType>({
    connected: false,
    chainId: 0,
    provider: null,
    account: '',
    wethOrWmaticBalance: null,
    ethOrMaticBalance: null,
    currency: null,
    networkDetails: null,
    providerType: '',
    onDisConnect: false,
    refetchBalance: false,
    openMetaMask: false,
  });

  return (
    <React.Fragment>
      <ConnectContext.Provider value={{ connect, setConnect }}>
        {children}
      </ConnectContext.Provider>
    </React.Fragment>
  );
};
