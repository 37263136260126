export * from "./strings.constants";
export const Configuration = {
  COINBASE_URL_PARAM_FROM_KEY: "from",
  COINBASE_URL_PARAM_FROM_VALUE: "coinbase",
  COINBASE_URL_REG_EXP: /coinbase/,
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  AUTH0_REDIRECT_URI:
    process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI ||
    (typeof document !== "undefined" && document.location.origin) ||
    undefined,
  MOJITO_API_URL: process.env.NEXT_PUBLIC_MOJITO_API_URL,
  MOJITO_API_WS_URL: process.env.NEXT_PUBLIC_MOJITO_API_WS_URL,
  ORGANIZATION_ID: process.env.NEXT_PUBLIC_API_ORGANIZATION_ID,
  MARKETPLACE_ID: process.env.NEXT_PUBLIC_MARKETPLACE_ID,
  ETH_VALUE_MULTIPLIER: parseInt(process.env.NEXT_PUBLIC_ETH_VALUE_MULTIPLIER!),
  COLLECTION_SLUG: process.env.NEXT_PUBLIC_COLLECTION_SLUG!,
  CONTENTFUL_SPACE: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE!,
  CONTENTFUL_ACCESS_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN!,
  CONTENTFUL_ENV: process.env.NEXT_PUBLIC_CONTENTFUL_ENV!,
  APP_METADATA_URL: process.env.NEXT_PUBLIC_APP_METADATA_URL || "",
  CONTENTFUL_URL: process.env.NEXT_PUBLIC_CONTENTFUL_URL || "",
  CONTENTFUL_AUTH_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_AUTH_TOKEN || "",
  // MOJITO_API_URL: process.env.NEXT_PUBLIC_MOJITO_API_URL || '',
  CONTENTFUL_LANDINGPAGE_ENTRYID: process.env.NEXT_PUBLIC_CONTENTFUL_LANDINGPAGE_ENTRYID || '',
  PROJECT_ID: process.env.NEXT_PUBLIC_PROJECT_ID || '9d9a7f55b77da8f61a031cf718b773ca',
  PAPER_CLIENT_ID: process.env.NEXT_PUBLIC_PAPER_CLIENT_ID || '',
  CHECKOUT_API_KEY: process.env.NEXT_PUBLIC_CHECKOUT_API_KEY || '',
  SLUG: process.env.NEXT_PUBLIC_SLUG || '',
  CHAIN_ID: process.env.NEXT_PUBLIC_CHAINID || 11155111,
  SARDINE: process.env.NEXT_PUBLIC_RUNTIME_SARDINE_ENABLED || false,
  SARDINE_ENV: process.env.NEXT_PUBLIC_RUNTIME_SARDINE_ENVIRONMENT || 'sandbox',
  PAPER_NETWORK_NAME: process.env.NEXT_PUBLIC_PAPER_NETWORK_NAME || 'Sepolia',
  SARDINE_CLIENT_ID: process.env.NEXT_PUBLIC_RUNTIME_SARDINE_CLIENT_ID || '452ae70e-49e7-4451-8294-066f86ae13fe',
  SARDINE_HOST: process.env.NEXT_PUBLIC_RUNTIME_SARDINE_HOST || 'api.sandbox.sardine.ai',
  META_PIXEL_ID: process.env.NEXT_PUBLIC_META_PIXEL_ID || '295345586635508',
  CLIENT_ID: process.env.NEXT_PUBLIC_CLIENT_ID || '6d88de19e1a489546ad58e294c8dba4b',
  THIRD_WEB_CLIENT_ID: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID || '15aecf615992b92b4f1a1829d525e4f9',
  THIRD_WEB_ACTIVE_CHAIN:process.env.NEXT_PUBLIC_THIRDWEB_ACTIVE_CHAIN || 'sepolia',
  CONTENTFUL_LANDING_PAGE: process.env.NEXT_PUBLIC_CONTENTFUL_LANDING_PAGE || 'landingPageUAT'
};

export const SessionVariable = {
  NetworkDetails: 'NetworkDetails',
  WalletDetails: 'WalletDetails',
  AuthToken: 'authToken',
};