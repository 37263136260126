import { useSignature, useConnectWallet } from '@mojito-inc/core-service';
import { useCallback, useMemo, useEffect } from 'react';
import { SessionVariable } from '../constant';
import { session } from '../utils/sessionStorage.utils';

interface Configuration {
  orgId: string;
  isTokenGating?: boolean;
}

interface ApiResponse {
  status: boolean;
  message: string;
}

interface ConnectWalletParam {
  getSignature: (walletAddress: string, networkId: string) => Promise<ApiResponse | undefined>;
  loginWithWallet: (signatureMessage: string, signer: string, signature: string, chainId: number) => Promise<ApiResponse | undefined>
  isTokenGating: boolean;
}

const useWalletConnect = ({
  orgId,
  isTokenGating = false,
}: Configuration): ConnectWalletParam => {
  const { getSignatureMessage, loginWithSignature } = useSignature();
  const { getSupportedNetworks } = useConnectWallet();


  const getSignature = useCallback(
    async (walletAddress: string, networkId: string) => {
      try {
        const signature = await getSignatureMessage({
          orgId: orgId ?? '',
          networkID: networkId,
          walletAddress,
        }, { fetchPolicy: 'no-cache' });
        return { status: true, message: signature?.data?.getSignatureMessage };
      } catch (error: any) {
        return { status: false, message: error?.message };
      }
    },
    [getSignatureMessage, orgId],
  );

  const loginWithWallet = useCallback(
    async (signatureMessage: string, signer: string, signature: string, chainId: number) => {
      try {
        const response = await loginWithSignature({
          orgId,
          signer,
          challenge: signatureMessage,
          signature,
          chainId,
        });
        return { status: true, message: response?.data?.loginWithSignature?.token };
      } catch (error: any) {
        return { status: false, message: error?.message };
      }
    },
    [loginWithSignature, orgId],
  );

  const getNetworks = useCallback(async () => {
    try {
      const response = await getSupportedNetworks({
        includeTestnets: true,
        orgId,
      });
      session(SessionVariable.NetworkDetails, false, response.data?.getSupportedNetworks);
      return response.data?.getSupportedNetworks;
    } catch (error) {
      return [];
    }
  }, [orgId, getSupportedNetworks]);

  useEffect(() => {
    getNetworks();
  }, [getNetworks]);

  return useMemo(() => {
    return { loginWithWallet, getSignature, isTokenGating };
  }, [loginWithWallet, getSignature, isTokenGating]);
};
export default useWalletConnect;
