import { ethers } from 'ethers';
import { truncateDecimal } from './truncateDecimal.utils';
import { getABIFile } from './getABIFile.utils';
import { getCurrencyAddress } from './getCurrencyAddress.utils';
import { WalletProviderType } from '../constant';

interface BalanceData {
  native: number;
  nonNative: number;
}

export async function getNonNativeCurrencyBalancePaper(
  provider: ethers.providers.Web3Provider | any,
  currencyContractAddress: string,
  currency: string,
): Promise<number> {
  try {
    const signerAddress = await provider.getAddress();
    const abiFile = getABIFile(currency);
    const contract = new ethers.Contract(
      currencyContractAddress,
      abiFile,
      provider,
    );
    const balanceOf = await contract.balanceOf(signerAddress);
    const balance = balanceOf / 1e18;
    return balance;
  } catch (error) {
    return 0;
  }
}

export async function getNonNativeCurrencyBalance(
  provider: ethers.providers.Web3Provider | any,
  currencyContractAddress: string,
  currency: string,
): Promise<number> {
  try {
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    const abiFile = getABIFile(currency);
    const contract = new ethers.Contract(
      currencyContractAddress,
      abiFile,
      signer,
    );
    const balanceOf = await contract.balanceOf(signerAddress);
    const balance = balanceOf / 1e18;
    return balance;
  } catch (error) {
    return 0;
  }
}

export const getBalance = async (
  provider: ethers.providers.Web3Provider | any,
  walletAddress: string,
  chainId: number,
  providerType: string,
): Promise<BalanceData> => {
  try {
    const currency = (chainId === 80001 || chainId === 137) ? 'WMATIC' : 'WETH';
    const contractAddress = getCurrencyAddress(chainId);
    let balanceData = null;
    let nonNativeBalance = null;
    if (providerType === WalletProviderType.EMAIL) {
      balanceData = await provider?.provider?.getBalance(walletAddress);
      nonNativeBalance = await getNonNativeCurrencyBalancePaper(
        provider,
        contractAddress,
        currency,
      );
    } else {
      nonNativeBalance = await getNonNativeCurrencyBalance(
        provider,
        contractAddress,
        currency,
      );
      balanceData = await provider?.getBalance(walletAddress);
    }
    const nativeBalance = ethers.utils.formatEther(String(balanceData));
    return {
      native: +truncateDecimal(nativeBalance),
      nonNative: +truncateDecimal(nonNativeBalance),
    };
  } catch (error) {
    return {
      native: 0,
      nonNative: 0,
    };
  }
};
