import { Configuration } from "@constants";
import { GraphQLClient } from "graphql-request";
import { Variables } from "graphql-request";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import {
  contentfulQueries,
  EContentfulQueries,
  IUseQueryResult,
} from "../data/index";
import {
  IContentfulAuction,
} from "../interfaces/index";
import { contentfulNormalizer } from "../utils/gqlDataNormalizer.util";
import { gqlRequest } from "../utils/index";

export const contentfulGqlClient = new GraphQLClient(
  Configuration.CONTENTFUL_URL,
  {
    headers: {
      Authorization: `Bearer ${Configuration.CONTENTFUL_AUTH_TOKEN}`,
    },
  }
);

export function useContentful<T = any, V = Variables>(
  query: EContentfulQueries,
  variables?: any,
  options?: UseQueryOptions<T>
): UseQueryResult<T> & { loading: boolean } {
  const queryKey: any[] = [`Contentful ${EContentfulQueries[query]}`];
  if (variables) queryKey.push(variables);
  const result = useQuery<T>(
    queryKey,
    async () =>
      await gqlRequest<T>({
        query: contentfulQueries[query],
        variables,
        normalizerFn: contentfulNormalizer,
        gqlClient: contentfulGqlClient,
      }),
    options
  );
  return {
    loading: result.isLoading,
    ...result,
  };
}

export function useContentfulAuctionsSlugList(): {
  auctionsSlugList: string[];
  auctionsSlugListLoading: boolean;
  auctionsSlugListError: IUseQueryResult["error"];
} {
  const { data, error, loading } = useContentful(
    EContentfulQueries.auctionsSlugList
  );
  if (error) console.error(error);

  return {
    auctionsSlugList:
      data?.auctionCollection?.items?.map(
        (collection: IContentfulAuction) => collection.slug
      ) ?? [],
    auctionsSlugListLoading: loading,
    auctionsSlugListError: error,
  };
}
