import React, { createContext, useContext, useState, useMemo } from 'react';

export interface AuthTokenType {
  token: string | undefined;
  isLogin: boolean;
}

export interface ContextType {
  authDetails: AuthTokenType;
  setAuthDetails(f: AuthTokenType | ((prev: AuthTokenType) => AuthTokenType)): void;
}

interface AuthDetailsProviderProps {
  children: React.ReactNode;
}

const Context = createContext<ContextType>({} as ContextType);

export default Context;

export function useAuthDetails() {
  return useContext(Context);
}

export const AuthDetailsProvider = ({
  children,
}: AuthDetailsProviderProps) => {
  const [authDetails, setAuthDetails] = useState<AuthTokenType>({
    token: undefined,
    isLogin: false,
  });

  const contextValue = useMemo(() => (
    { authDetails, setAuthDetails }
  ), [authDetails, setAuthDetails]);

  return (
    <Context.Provider value={ contextValue }>
      { children }
    </Context.Provider>
  );
};
