import React, { useState, ReactNode } from "react";
import UserContext, { UserType } from "../utils/userContext";

interface LayoutProps {
  children: ReactNode;
}

export const UserContextProvider = ({
  children,
}: LayoutProps): JSX.Element => {
  const [userData, setUserData] = useState<UserType>({
    id: 0,
    kycStatus: '',
    reason: '',
    username: ''
  });

  return (
    <React.Fragment>
      <UserContext.Provider value={{ userData, setUserData }}>
        {children}
      </UserContext.Provider>
    </React.Fragment>
  );
};
