import { gql } from "graphql-request";

export enum EContentfulQueries {
  fullLot,
  shortLots,
  lotsImages,
  auctionBySlug,
  auctionsSlugList,
  collectors,
  authors,
  organizations,
  lotsAction,
  additionalSales,
  additionalSalesBySlug,
}

export const contentfulQueries = {
  [EContentfulQueries.fullLot]: gql`
    query lot($mojitoId: String) {
      lotCollection(where: { mojitoId: $mojitoId }) {
        items {
          lotId
          sys {
            publishedAt
          }
          title
          subtitle
          imagesCollection {
            items {
              url
              title
              contentType
            }
          }
          createdAt
          estimatePrice
          purchasedAt
          smartContractAddress
          tokenId
          mojitoId
          author {
            about
            name
            slug
            avatar {
              url
              title
            }
          }
          collector {
            name
            slug
            about
            smartContractAddress
            avatar {
              url
              title
            }
            videoId
            twitterLink
          }
          aboutLot
          note
          history
          video
          conditionReportText
          shortCollectorDescription
          nftLink
          slug
          nftVideoId
          nftVideoIds
          soldOut
          lotPreviewBackgroundColor
          gridPreviewImage {
            url
            title
          }
        }
      }
    }
  `,
  [EContentfulQueries.shortLots]: gql`
    query lots($mojitoIds: [String]) {
      lotCollection(order: lotId_ASC, where: { mojitoId_in: $mojitoIds }) {
        items {
          sys {
            publishedAt
          }
          lotId
          title
          subtitle
          mojitoId
          author {
            name
            slug
            avatar {
              url
            }
          }
          collector {
            name
            slug
            avatar {
              url
            }
          }
          slug
          gridPreviewImage {
            url
            title
            width
            height
          }
          imagesCollection(limit: 1) {
            items {
              url
              title
            }
          }
          nftVideoId
          soldOut
          estimatePrice
          purchasedAt
          createdAt
          shortCollectorDescription
        }
      }
    }
  `,
  [EContentfulQueries.lotsImages]: gql`
    query lots($mojitoIds: [String]) {
      lotCollection(order: lotId_ASC, where: { mojitoId_in: $mojitoIds }) {
        items {
          sys {
            publishedAt
          }
          mojitoId
          gridPreviewImage {
            url
            title
            width
            height
          }
          lotPreviewBackgroundColor
          imagesCollection(limit: 1) {
            items {
              url
              title
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.auctionBySlug]: gql`
    query Auction($slug: String) {
      auctionCollection(order: sys_publishedAt_DESC, where: { slug: $slug }) {
        items {
          name
          title
          subtitle
          duration
          description
          data
          startDate
          endDate
          videoId
          slug
          saleId
          footer
          enquireEmail
          claimItemId
          claimSetId
          overviewCurrency
          overviewSaleClosed
          overviewTotalTransacted
          overviewLotsSold
          headerBg {
            url
            title
            width
            height
          }
          faqCollection {
            items {
              title
              content
            }
          }
          author {
            about
            name
            avatar {
              url
              title
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.auctionsSlugList]: gql`
    query Auction {
      auctionCollection(order: sys_publishedAt_DESC) {
        items {
          slug
        }
      }
    }
  `,
  [EContentfulQueries.collectors]: gql`
    query Collector {
      collectorCollection(order: name_ASC) {
        items {
          sys {
            publishedAt
          }
          name
          about
          smartContractAddress
          linkedFrom {
            lotCollection {
              items {
                mojitoId
                title
                slug
              }
            }
          }
          avatar {
            url
            title
          }
          videoId
          twitterLink
          slug
        }
      }
    }
  `,
  [EContentfulQueries.authors]: gql`
    query Author {
      authorCollection {
        items {
          sys {
            publishedAt
          }
          about
          name
          slug
          avatar {
            url
            title
          }
          linkedFrom {
            lotCollection {
              items {
                mojitoId
                title
                slug
              }
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.organizations]: gql`
    query Organizations {
      organizationCollection {
        items {
          homepageRedirect {
            name
            slug
          }
        }
      }
    }
  `,
  [EContentfulQueries.lotsAction]: gql`
    query Auction($slug: String) {
      auctionCollection(where: { slug: $slug }) {
        items {
          lotsCollection {
            items {
              mojitoId
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.additionalSales]: gql`
    query Auction($slug: String) {
      auctionCollection(where: { slug: $slug }) {
        items {
          additionalSalesCollection {
            items {
              slug
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.additionalSalesBySlug]: gql`
    query offLineAdditionalSale($slug: [String]) {
      offLineAdditionalSaleCollection(where: { slug_in: $slug }) {
        items {
          title
          subCopy
          description
          buttonCta
          image {
            url
            title
            width
            height
          }
          saleLink
        }
      }
    }
  `,
};
// 6267c3d0-bdba-45a2-bcca-6c7ff10fd193
// 6678e79a-ae68-450f-bd7f-21e47bed3e5f
// lotCollection(order: lotId_ASC, where: {OR: [{mojitoId: "6678e79a-ae68-450f-bd7f-21e47bed3e5f"}, {mojitoId: "6267c3d0-bdba-45a2-bcca-6c7ff10fd193"}]}) {
