export const LOCAL_STORAGE_STATE: any = {
  secondary_wallet: {
    connect_wallet: "connect_wallet",
    connect_wallet_message: "connect_wallet_message",
    providerType: "providerType",
    store_network_details: "store_network_details",
  },
};

export const connectWallet = {
  metaMask: 'META_MASK',
  walletConnect: 'WALLET_CONNECT'
}

export function clearStorage ( stateList: any ): void {
  Object.values(stateList).map((item: any) => {
    window.localStorage.removeItem(item);
    return null;
  });
}