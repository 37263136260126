import React, { useCallback, useContext, useState } from "react";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from '@mui/material/Button';
import { useAuth0 } from "@auth0/auth0-react";
import Context from "../../utils/connectContext";
import { getChainExplore, truncateAddress } from "../../utils/commonCheck";
import connectWalletStyle from "./connectWallet.module.css";
import Typography from "@mui/material/Typography";
import { ErrorSuccessModal } from "@components/ConnectMetamask/components/errorSuccessModal";

const walletMenu = ["View on Etherscan", "Disconnect"];

interface ConnectWalletProps {
    isMobile?: boolean;
}

interface SupportedNetworksData {
  chainID: number
  id: string;
  isTestnet: boolean;
  name: string;
}

export interface WalletDetailsData {
  walletAddress: string;
  networkDetails: SupportedNetworksData;
  providerType: string;
  balance: {
    native: number;
    nonNative: number;
  };
  provider: any;
}

const ConnectWalletLayout = ({isMobile = false}: ConnectWalletProps) => {
  const { isAuthenticated } = useAuth0();
  const { connect, setConnect } = useContext(Context);
  const [anchorConnectWallet, setAnchorConnectWallet] = useState(null);
  const [disConnect, setDisConnect] = useState({
    message: '',
    status: false,
  });

  const getClassName = () => {
    if (isMobile) {
      return connectWalletStyle?.hideMobile
    } else {
      return connectWalletStyle?.showMobile
    }
  }

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const handleOpenWalletMenu = useCallback((event: any) => {
    setAnchorConnectWallet(event.currentTarget);
  }, []);

  const handleCloseConnectWalletMenu = useCallback((text: string) => {
    setAnchorConnectWallet(null);
    if (connect?.connected) {
      if (text === "View on Etherscan") {
        openInNewTab(getChainExplore(connect?.chainId ?? 0, connect?.account ?? '', '', 'address'));
      } else if (text === "Disconnect") {
        setDisConnect({
          message: 'Wallet has been disconnected successfully',
          status: true
        });
        setConnect(prev => ({
          ...prev,
          account: '',
          signer: null,
          provider: null,
          connected: false,
          chainId: null,
          wethOrWmaticBalance: null,
          ethOrMaticBalance: null,
          currency: null,
          onDisConnect: true,
          openMetaMask: false,
          refetchBalance: false,
        }));
      }
    }
  }, [connect?.connected, connect?.account, connect?.chainId, connect?.networkDetails?.isTestnet]);

  const handleCloseModal = useCallback(() => {
    setDisConnect({
      message: '',
      status: false
    });
  }, []);

  return (
    <>
      {isAuthenticated && (
        <>
          <Box className={getClassName()}>
            {connect?.connected ? (
              <Button
                sx={{height: '40px', marginTop: {sm: '0px', lg: '5px'}}}
                onClick={handleOpenWalletMenu}
                startIcon={<AccountBalanceWalletOutlinedIcon />}
                size="small"
                variant="outlined"
              >
                {truncateAddress(connect?.account)}
              </Button>
            ) : (
              <Button
                sx={{height: '40px', marginTop: '5px'}}
                onClick={() => {
                  setConnect(prev => ({
                    ...prev,
                    openMetaMask: true,
                  }));
                }}
                size="small"
                variant="outlined"
              >
                connect wallet
              </Button>
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorConnectWallet}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              className="box-shadow"
              open={Boolean(anchorConnectWallet)}
              onClose={handleCloseConnectWalletMenu}
            >
              <Box className={connectWalletStyle.menu_list}>
                {walletMenu?.map((item) => (
                  <MenuItem
                    key={item}
                    onClick={() => handleCloseConnectWalletMenu(item)}
                  >
                    <Typography textAlign="center">{item}</Typography>
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </Box>
          <ErrorSuccessModal disConnect={disConnect} handleClose={handleCloseModal} open={disConnect?.status} />
        </>
      )}
    </>
  );
};

export default ConnectWalletLayout;
