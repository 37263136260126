import { createContext } from "react";

export interface UserType {
  id: number;
  username: string;
  kycStatus: string;
  reason: string;
}
export interface ContextType {
  userData: UserType;
  setUserData(f: UserType | ((prev: UserType) => UserType)): void;
}
const UserContext = createContext<ContextType>({} as ContextType);
export default UserContext;

