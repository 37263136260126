export enum ModalType {
  CONNECT_WALLET = 'CONNECT_WALLET',
  LOADING = 'LOADING',
  OTP = 'OTP',
  ERROR = 'ERROR',
  EMAIL = 'EMAIL',
  RECOVERY_CODE = 'RECOVERY_CODE',
  CLAIM_DISCOUNT = 'CLAIM_DISCOUNT',
  NO_CLAIM = 'NO_CLAIM',
  GATING_LOADING = 'GATING_LOADING',
  GATING_ERROR = 'GATING_ERROR',
}

export interface ImageProps {
  logo: string;
  metamask: string;
  walletConnect: string;
  error?: string;
}

export interface WalletOptionsProps {
  enableMetamask: boolean;
  enableWalletConnect: boolean;
  enableEmail: boolean;
}

export interface ContentData {
  title?: string;
  description?: string;
}

export interface ContentProps {
  walletOptionsContentData?: ContentData;
  otpContentData?: ContentData;
  emailContentData?: ContentData;
}

export interface ConnectWalletLayoutProps {
  open: boolean;
  error: string;
  email: string;
  otp: string;
  recoveryCode: string;
  image: ImageProps;
  currentModalState: ModalType;
  loaderTitle: string;
  walletOptions: WalletOptionsProps;
  content?: ContentProps;
  onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOTP: (event: string) => void;
  onChangeRecoveryCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickContinueWithEmail: () => void;
  onClickEmail: () => void;
  handleRetry: () => void;
  onClickVerifyOTP: () => void;
  onClickMetamask: () => void;
  onClickWalletConnect: () => void;
  onClickRecoveryCode: () => void;
  onCloseModal: () => void;
}

interface WalletConfiguration {
  orgId: string;
  chainId: number;
  paperClientId?: string;
  paperNetworkName?: any;
  projectId?: string;
  groupId?: string;
  ruleId?: string;
  isClaimToken?: boolean;
  collectionItemId?: string;
  invoiceId?: string;
}

export interface SupportedNetworksData {
  chainID: number
  id: string;
  isTestnet: boolean;
  name: string;
}

export interface BalanceData {
  native: number;
  nonNative: number;
}

export interface WalletDetailsData {
  walletAddress: string;
  networkDetails: SupportedNetworksData
  providerType: string;
  balance: BalanceData;
  provider: any;
}

export interface AppMetaData {
  appName: string;
  appDescription: string;
  appURL: string;
  logo: string;
}

export interface ConnectWalletProps {
  open: boolean;
  isWeb2Login?: boolean;
  walletAddress?: string;
  isDisConnect: boolean;
  config: WalletConfiguration;
  walletOptions: WalletOptionsProps;
  image: ImageProps;
  content?: ContentProps;
  isRefetchBalance?: boolean;
  appMetaData?: AppMetaData;
  skipSignature?: boolean;
  onCloseModal: (event?: boolean) => void;
  onChangeWalletAddress?: (walletDetails: WalletDetailsData) => void;
}

export interface ConnectWalletActionProps {
  onClickMetamask?: () => void;
  onClickWalletConnect?: () => void;
  onClickEmail?: () => void;
}

export interface WalletImages {
  metamask?: string;
  walletConnect?: string;
  logo?: string;
  error?: string;
}

export interface ScreenConfig {
  title?: string;
  subTitle?: string
}
