import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import styles from "./search.module.css";

interface searchProps {
  onchange?: (e: any) => void;
  value?: string;
  onClickSearch?: () => void;
  white?:boolean;
}
export default function SearchComponent({
  onchange,
  value,
  onClickSearch,
  white
}: searchProps) {
  return (
    <Box
      //   sx={{
      //     width: 500,
      //     maxWidth: '100%',
      //   }}
      className={`${styles.search_main} ${white && 'white'} search-box-wrap`}
    >
      <TextField
        fullWidth
        placeholder="Search"
        value={value}
        onChange={(e: any) => {
          if (onchange) {
            onchange(e?.target?.value);
          }
        }}
        onKeyPress={(event: any) => {
          if (event.key === "Enter") {
            if (onClickSearch) onClickSearch();
          }
        }}
      />
      <span onClick={onClickSearch}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="27"
          viewBox="0 0 37 27"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.6893 13.96H0V12.46H33.6893L22.4697 1.24035L23.5303 0.179688L36.0303 12.6797L36.5607 13.21L36.0303 13.7403L23.5303 26.2403L22.4697 25.1797L33.6893 13.96Z"
            fill="black"
          />
        </svg>
      </span>
    </Box>
  );
}
